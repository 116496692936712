import { useAuthService } from "../contexts/auth-context";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hook";
import { loadProjects, setProjectsLoaded } from "../redux/projects";
import { loadTemplates } from "../redux/projectTemplates";
import { resetSettings } from "../redux/userSettings";
import { loadUsers } from "../redux/users";
import { resetCustomFieldValues } from "../redux/custom-fields";
import { useNavigate } from "react-router-dom";
import moment, { Moment } from "moment";
import {useCustomModal} from "../pages/modals/custom-message-modal";
import {INACTIVE_TIME_LIMIT_IN_PLATFORM, TIME_TO_SHOW_INACTIVE_TIMER} from "../constants";

export function InactivityBar() {

    const auth = useAuthService();
    const dispatch = useAppDispatch();
    const nav = useNavigate();
    const { hideModal } = useCustomModal();
    const startTime = useRef<Moment>(moment());
    const projects = useAppSelector((state) => state.projects);
    const [isProcessRunning, setIsProcessRunning] = useState(false);
    const bc = new BroadcastChannel('inactivity');

    bc.onmessage = (event) => {
        if (event.data === "reset") {
            startTime.current = moment();
            auth.setInactiveTime(0);
        }
    }

    const isSomeTaskCurrentlyBeingTimed = () => {
        for (const project of projects.projects!) {
            for (const taskList of project.taskLists!) {
                for (const task of taskList.tasks!) {
                    const taskTimeTrackedArray = task.timeTrackedByUserId?.split(",") || [];
                    if (taskTimeTrackedArray.includes(String(auth.loginInfo?.tenant?.user?.id))) {
                        return true;
                    }
                    if (task.subtasks) {
                        for (const subtask of task.subtasks!) {
                            const subtaskTimeTrackedArray = subtask.timeTrackedByUserId?.split(",") || [];
                            if (subtaskTimeTrackedArray.includes(String(auth.loginInfo?.tenant?.user?.id))) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    useEffect(() => {
        if (!isSomeTaskCurrentlyBeingTimed() && !isProcessRunning) {
            mouseMoveListener();
            const interval = setInterval(intervalFunc, 1000);
            window.addEventListener('mousemove', mouseMoveListener);
            return () => {
                clearInterval(interval);
                window.removeEventListener('mousemove', mouseMoveListener);
            };
        }
    }, [projects, isProcessRunning]);

    useEffect(() => {
        window.addEventListener('processStarted', processStartedListener)
        window.addEventListener('processEnded', processEndedListener)
        return () => {
            window.removeEventListener('processStarted', processStartedListener)
            window.removeEventListener('processEnded', processEndedListener)
        }
    }, []);

    const clearReduxStore = () => {
        dispatch(loadProjects([]));
        dispatch(setProjectsLoaded(false));
        dispatch(loadTemplates([]));
        dispatch(resetSettings());
        dispatch(loadUsers([]));
        dispatch(resetCustomFieldValues());
    }

    const intervalFunc = () => {
        const inactiveTimeOutsidePlatform = localStorage.getItem("inactiveTimeOutsidePlatform");
        if (moment().diff(moment(inactiveTimeOutsidePlatform), 'milliseconds') < INACTIVE_TIME_LIMIT_IN_PLATFORM || !inactiveTimeOutsidePlatform) {
            localStorage.setItem("inactiveTimeOutsidePlatform", moment().toISOString());
        }

        //Log out the user if the app is open but they've been inactive for more than 15 minutes.
        const ms = moment().diff(startTime.current, 'milliseconds');
        if (ms > INACTIVE_TIME_LIMIT_IN_PLATFORM) { //15 minutes
            hideModal()
            clearReduxStore();
            nav("/inactivity-logout")
            localStorage.removeItem("userPayload");
            auth.setLoginInfo(undefined);
        }
        //If they've been inactive for just 10 minutes, don't log them out but start the countdown timer.
        if (ms > TIME_TO_SHOW_INACTIVE_TIMER) {
            auth.setInactiveTime(Math.trunc(ms / 1000));
        }
    }

    const mouseMoveListener = () => {
        if (auth) {
            startTime.current = moment();
            auth.setInactiveTime(0);
            bc.postMessage("reset");
        }
    }

    const processStartedListener = () => {
        console.log('process started, the inactivity timer is turned off.');
        setIsProcessRunning(true);
    }

    const processEndedListener = () => {
        console.log('process ended, the inactivity timer is back on.');
        setIsProcessRunning(false);
    }

    return <></>
}
