import WebViewerContext from '../contexts/webviewer-context';
import {useEffect, useRef, useContext, useState, useCallback} from 'react';
import WebViewer, { Core } from '@pdftron/webviewer';
import { useAuthService } from "../contexts/auth-context";
import DefaultRedactSearchPatterns from './default-redact-search-patterns';
import {generateMarksReport, generateReport, generateTransformsReport} from "../services/ReportGenerator";
import { generatePatientReport } from "../services/PatientReportGenerator";
import { generateSearchResultsReport } from "../services/SearchResultsReportGenerator";
import {getApiDefaultPatterns, getApiPatternsBySetId} from "../services/patternSets";
import { Pattern } from "../models/Pattern";
import { getPatternsForDocViewer } from "../pages/user/single-pattern";
import { useCustomModal } from "../pages/modals/custom-message-modal";
import SmartFilterManager from "../tools/smart-filter-manager";
import {updateFileUsingS3, uploadFileUsingS3} from "./file-management";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hook";
import {
    CATEGORY_SPLITTER,
    HIGHLIGHT_TEXT,
    fullPageRedactionStyle,
    markStyles,
    REPLACEMENT_TEXT_COLUMN,
    PATIENT_ID_COLUMN, START_DATE_COLUMN
} from '../constants';
import { filesSelector, loadFiles } from '../redux/file-management';
import { FileState } from '../models/FileState';
import {
    createLogFileByProjectId,
    getApiFilesByProjectId,
    getFileTagByProjectIdAndFileName,
    ocrPDFFIle,
    ocrCompletenessStatusCheck,
    putApiFileOpenStatusById,
    getFontUrl,
    sanitizeCompletenessStatusCheck,
    destroyTemporarySanitizeAsset,
    getEncodedFileName, deleteTemporaryLocalChangesFileById, onTextReflow, reflowCompletenessStatusCheck
} from "../services/files";
import { showSnackbar } from "../redux/snackbar";
import { hideProgressLine, showProgressLine } from "../redux/progress-line";
import SanitizeModal from '../pages/modals/sanitize-modal';
import SanitizeRemovalModal from '../pages/modals/sanitize-removal-modal';
import { sanitizePDFFIle } from '../services/files';
import * as Sentry from "@sentry/react";
import ChangeStatusModal from '../pages/modals/change-status-modal';
import { putApiTasksStatusById } from '../services/task';
import LoadTransformsFromRiskModal, {formatCategoricalTransform} from "../pages/modals/load-from-risk-modal";
import {getFileNameFromURL} from "../pages/user/projects/single-project";
import {comparisonReportGenerator} from "../services/ComparisonReportGenerator";
import SanitizeSuccessModal from "../pages/modals/sanitize-success-modal";
import {ManualMarkMenu} from "./ManualMarkMenu";
import DockLockConsentModal from "../pages/modals/doc-lock-consent-modal";
import {UpdateFileTagModal} from "../pages/modals/modal-content/update-file-tag-modal";
import OcrProcessingStatus from "../pages/modals/ocr-processing-status";
import {REDACTION_KEYWORD, RETAIN_KEYWORD} from "../models/DeidData";
import {useNavigatingAway} from "../hooks/use-navigation-away";
import UnsavedWarningModal from "../pages/modals/unsaved-warning-modal";
import OverwriteTransformsWarningModal from "../pages/modals/overwrite-transforms-warning-modal";
import TransformFromReportModal from "../pages/modals/transform-from-report-modal";
import { filterPDFExtension } from './files-component';
import {Multipanel} from "./webviewer-multipanel/multipanel";
import AnnotationRepeatPopup from "./AnnotationRepeatPopup";
import {MarkStyleDropdown} from "./MarkStyleDropdown";
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import LoadTransformsContext from "../contexts/load-transforms-context";
import FilterFunctionContext from "../contexts/filter-function-context";
import axios from 'axios';
import SelectedMarkStyleContext from "../contexts/selected-mark-style-context";
import SearchAcrossDocsContext from "../contexts/SearchAcrossDocsContext";
import {pageMarginsSelector} from "../redux/pagemargins";
import LoadFromCategoryModal from "./webviewer-multipanel/load-from-category-modal";
import OutlineColorPickerModal from "./OutlineColorPickerModal";
import {getStyleAndDisplayCategory} from "./webviewer-multipanel/SearchPanel/RedactionSearchResultsContainer";
import {loadTransformsFromLookup} from "../services/LookupFileReader";

let closeBtn: any;
let currentDoc: any;
export default function WebViewerComponent(p: { files: any[], patternSetID?: number, projectID?: number, taskId?: number, initialTaskStatus?: string, isRestoring? : boolean, tag?: string}) {
    const { setInstance,instance } = useContext(WebViewerContext);
    const { setLoadTransformCallbacks} = useContext(LoadTransformsContext);
    const {setFilterFunction} = useContext(FilterFunctionContext);
    const {setSearchAcrossDocs} = useContext(SearchAcrossDocsContext);
    const {setSelectedMarkStyle} = useContext(SelectedMarkStyleContext)
    const taskStatus = useRef(p.initialTaskStatus);
    let isRestoring = useRef(p.isRestoring);
    const dispatch = useAppDispatch();
    let clickedApplyFlag = false
    const { loaded, projects } = useAppSelector(state => state.projects)
    const activeProject = projects.find((val) => val.id === p.projectID);
    const { showModal, hideModal } = useCustomModal();
    const { loginInfo } = useAuthService();
    const viewer = useRef(null);
    const projectFiles: FileState[] = useAppSelector(filesSelector);
    let leftMarginPoints = useAppSelector(state => pageMarginsSelector(state)).left;
    let rightMarginPoints = useAppSelector(state => pageMarginsSelector(state)).right;
    let headerMarginPoints = useAppSelector(state => pageMarginsSelector(state)).header;
    let footerMarginPoints = useAppSelector(state => pageMarginsSelector(state)).footer;
    const autosaveIntervalRef = useRef<any>(null)
    const [addCommentButton, setAddCommentButton] = useState<HTMLElement>()
    const [searchBarOptions, setSearchBarOptions] = useState<{label: string, type: string, regex: RegExp}[]>([]);
    const [isEdited, setIsEdit] = useState<boolean>(false);
    const [showDialogPrompt,setShowDialogPrompt, confirmNavigation, cancelNavigation] = useNavigatingAway(isEdited)
    const [editedDocs, setEditedDocs] = useState<Array<number>>([])
    const [continueTracking, setContinueTracking] = useState<boolean>(false)
    const [sanitizationPayloadHelper, setSanitizationPayloadHelper] = useState<Record<string, string | boolean>>({isNew: true})
    const [data, setSanitizedData] = useState<any>({})
    const [currentBtn, setCurrentBtn] = useState();
    const nav = useNavigate();
    //These are the font size that I see available in the webviewer
    const fontSizeValues = ['1pt', '2pt', '3pt', '4pt', '5pt', '6pt', '7pt', '8pt', '9pt', '10pt', '11pt', '12pt', '13pt', '14pt', '15pt', '16pt', '17pt', '18pt', '19pt', '20pt',
        '22pt', '24pt', '26pt', '28pt', '30pt', '32pt', '34pt', '36pt', '38pt', '40pt', '42pt', '44pt', '46pt', '48pt', '52pt', '56pt', '60pt', '64pt', '68pt', '72pt', '76pt',
        '80pt', '84pt', '88pt', '92pt', '96pt', '100pt', '104pt', '108pt', '112pt', '116pt', '120pt', '124pt'
    ]
    const fontSizeOptions = ['6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '18', '20', '22', '24', '26', '28', '36', '72']
    const fontNames = ['Arial', 'Baskerville', 'Baskerville Old Face', 'Cambria', 'Comic Sans MS', 'Didot', 'Georgia', 'Gill Sans', 'Helvetica', 'Sas Monospace', 'Rockwell', 'Times New Roman']
    let isBulkMarkAdjustSelected = false;
    let fileNameAndIdMap = new Map<string, string>();
    const trackEditedFiles = (activeDoc: number,iframeDoc?: any, type?: string) => {
        console.log(activeDoc)
        const prevEditedDocs = editedDocs;
        const isExist = prevEditedDocs.indexOf(activeDoc) > -1;
        if(type === 'add') {
            const closeButtons =  iframeDoc.querySelectorAll('.TabsHeader .draggable-tab .close-button-wrapper .Button');
            const selectedButton = [...closeButtons].find((button: HTMLElement) => button.dataset.index === activeDoc.toString())
            selectedButton!.parentNode!.style.position = 'relative'
            if (!isExist) {
                selectedButton.insertAdjacentHTML('afterend',`<div data-index="${activeDoc}" class="fake" style="position: absolute;left: 0; width: 100%; height: 100%; top: 0; background: transparent; z-index: 999"></div>`)
                prevEditedDocs.push(activeDoc);
                setEditedDocs(prevEditedDocs);
            }
        }else{
            if(isExist){
                removeDocsFromEditArgs(activeDoc)
            }
        }
        setContinueTracking(prev=>!prev)
    }

    const forceSaveButtonClick = async (documentViewer: any, payload?: Record<any, any>)=>{
        documentViewer.trigger('saveFile')
        if(payload) {
            await destroyTemporarySanitizeAsset(payload);
        }
    }
    const blobToBase64 = (blob: Blob) => {
        return new Promise((resolve, reject)=>{
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () =>{
                const base64Data = reader.result;
                resolve(base64Data)
            }
            reader.onerror = reject
        })
    }


    const retreieveBlobData = async (type?: string) => {
        const paresedData = JSON.parse(localStorage.getItem('blobData')!)
        return type ? Object.keys(paresedData)[0] : await fetch(paresedData[Object.keys(paresedData)[0]])
    }

    const removeDocsFromEditArgs = (activeDoc: number) => {
        const prevEditedDocs = editedDocs;
        prevEditedDocs.splice(prevEditedDocs.indexOf(activeDoc), 1);
        setEditedDocs(prevEditedDocs)
    }
    useEffect(() => {
        localStorage.removeItem('blobData')
    }, []);

    useEffect(() => {
        const iframe = instance?.iframeWindow
        if (iframe){
            iframe.document.querySelectorAll('.fake').forEach((tagTab:any)=>{
                tagTab.addEventListener('click', function (){
                    openWarningModal(tagTab.previousSibling, tagTab.dataset.index)
                })
            })
        }
    }, [continueTracking]);

    const loadLockConsentModal = (doc: any) => {
        showModal(DockLockConsentModal, {
            onSave: async () => {
                doc.querySelector('[data-element="changeOverlayFontButton"]')!.disabled = true;
                doc.querySelector('[data-element="changeOverlayFontButton"]')!.classList.add('disabled');
                doc.querySelector('[data-element="bulkAdjustMarksButton"]')!.disabled = true;
                doc.querySelector('[data-element="bulkAdjustMarksButton"]')!.classList.add('disabled');
                hideModal()
            },
            onRemoveSelected: async () => hideModal()
        })
    }
    const ocrProgressModalOpen = (title?: string) => {
        showModal(OcrProcessingStatus,{onSave: async () =>{},onRemoveSelected: () => {}, title: title})
    }
    const loadFiles = async ()=> {
        const files = await getApiFilesByProjectId(p.projectID!)
        const selectedFiles = []
        for (let i = 0; i < p.files.length; i++){
            const index = files.findIndex((file: any)=> getFileNameFromURL(file.url) === getFileNameFromURL(p.files[i]));
            if(index > -1) {
                // @ts-ignore
                fileNameAndIdMap.set(getFileNameFromURL(files[index].url) as string, files[index].id)
                if (p.isRestoring) {
                    selectedFiles.push(p.files[i]);
                } else {
                    selectedFiles.push(files[index].url) //use the URL from the most recent API call, because these AWS URLs expire after 15 minutes.
                }
            }
        }

        return selectedFiles;
    }

    const markDocAsClosed = async (docURL: string, isDocOpen: {isDocOpen: boolean}) => {
        const docName = getFileNameFromURL(docURL)
        const file = projectFiles.find(file => file.name === docName)

        if (file) {
            await putApiFileOpenStatusById(file.id, isDocOpen);
        } else {
            // If we save file as copy in the webviewer, a new file is created, and the original file is closed.
            // This does cause the redux store to be updated with the new file, but since the webviewer component
            // itself does not refresh, projectFiles, which is derived from the redux state, is not yet
            // updated with the new file. So, when we go to close the new file, it will not be found in projectFiles,
            // so we make an api call here to find its id in the db so we can mark its isDocOpen field as false upon closing it
            // in webviewer
            const storedFiles = await getApiFilesByProjectId(p.projectID!);
            const savedCopyFile = storedFiles.find(file => file.name === docName);
            if (savedCopyFile) {
                await putApiFileOpenStatusById(savedCopyFile.id, isDocOpen);
            }
        }
    }

    const deleteTemporaryLocalChanges = async (docURL: string) => {
        const docName = getFileNameFromURL(docURL)
        const file = projectFiles.find(file => file.name === docName)

        if (file) {
            console.log('deleting temporary')
            await deleteTemporaryLocalChangesFileById(file.id)
        }
    }
    useEffect(() => {
        if(showDialogPrompt){
            openWarningModal()
        }
    }, [showDialogPrompt]);

    const openWarningModal=async (btn?: any, index?: number)=>{
        if(btn && index){
            closeBtn = btn;
            currentDoc = index;
        }
        showModal(UnsavedWarningModal, {
            cancelPropagation: () =>{
                cancelNavigation();
                const documentViewer = instance.Core.documentViewer;
                forceSaveButtonClick(documentViewer);
            },
            discardChanges: ()=>{
                decideNavigationChangeOrDeleteTab()
            },
            continueChanges: () => {
                cancelNavigation();
                closeBtn = undefined;
                currentDoc = undefined}
        })
    }


    const decideNavigationChangeOrDeleteTab = () => {
        if(closeBtn && currentDoc) {
            removeDocsFromEditArgs(Number(currentDoc))
            closeBtn?.click()
            closeBtn = undefined;
            currentDoc = undefined
        }else {
            confirmNavigation();
        }
    }


    const onClose = (isDocOpen: {isDocOpen: boolean}) => {
        for (const file of p.files) {
            markDocAsClosed(file, isDocOpen);
            deleteTemporaryLocalChanges(file)
        }
        clearInterval(autosaveIntervalRef.current);
    }

    useEffect(() => {
        onClose({isDocOpen: true})
    }, []);

    // window.addEventListener('beforeunload', function (e) {
    //     onClose({isDocOpen: false});
    // });

    //The returned function from an effect acts as a cleanup.
    useEffect(() => {
        return ()=>onClose({isDocOpen: false})
    }, []);

    const [showingProgressSpinner, setShowingProgressSpinner] = useState(true);
    const [creatingReportInProgress, setcreatingReportInProgress] = useState(false);

    const showProgressSpinner = () => {
        setShowingProgressSpinner(true);
        window.dispatchEvent(new Event('processStarted'))
    }

    const hideProgressSpinner = () => {
        setShowingProgressSpinner(false);
        window.dispatchEvent(new Event('processEnded'));

    }

    const base64ToBlob = (base64: any, type = "application/octet-stream") => {
        const binStr = atob(base64);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
        }
        return new Blob([arr], { type: type });
    }
    const handleBeforeUnload = (event: any) => {
        onClose({isDocOpen: false})
        event.preventDefault();
        event.returnValue = false
    };
    useEffect(() => {
        window.addEventListener('beforeunload',  handleBeforeUnload, {capture: true});
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload, {capture: true});
        };

    }, [isEdited]);

    useEffect(() => {
        let interval: any;
        let ocrInterval: any;
        const openedFiles = async () => {
            return await loadFiles()
        }
        openedFiles().then(selectedFiles=> {
            console.log(JSON.stringify(selectedFiles))
        // @ts-ignore
        WebViewer({
            licenseKey: process.env.REACT_APP_PDFTRON_LICENSE,
            path: '/webviewer/lib',
            fullAPI: true,
            isAdminUser: true,
            enableRedaction: true,
            annotationUser: loginInfo?.tenant?.user?.name,
            //TODO put style overrides in here
            css: '/webviewer/lib/style-overrides.css', // Use this to override webviewer styles
            initialDoc: selectedFiles as string[],
            //This works only if all the selectedFiles are PDFs. We can change this if we change to supporting other file types. See https://docs.apryse.com/documentation/web/guides/multi-tab/#enabling-via-webviewer-constructor
            extension: ['pdf'],
            disabledElements: [
                'redactionPanel',
                'fullScreenButton'
            ]
        },
            viewer.current!,
        ).then(async (instance) => {



            showProgressSpinner()
            let zoomLevel: number | null = null;
            //Mouse movement in the webviewer doesn't get tracked by the main window. So we need this to activate the
            //listener that keeps the app from timing out.
            instance.UI.iframeWindow.addEventListener('mousemove', () => {
                window.dispatchEvent(new Event('mousemove'))
            })

            instance.Core.documentViewer.addEventListener('processStarted', (processType) => {
                //When the user applies a redaction. the event that gets triggered is that an annotation was deleted. However, we want the logs to say that the user applied redaction.
                // so we add a flag when the user clicks the button, so we can log the appropriate action
                if (processType === 'redact') {
                    clickedApplyFlag = true
                }
                if (processType !== 'search') {
                    showProgressSpinner()
                }
                window.dispatchEvent(new Event('processStarted'))
            })
            instance.Core.documentViewer.addEventListener('processEnded', (processType) => {
                hideProgressSpinner()
                window.dispatchEvent(new Event('processEnded'))
                // @ts-ignore
                switch(processType) {
                    case 'search':
                        dispatch(showSnackbar({ message: "Search Completed", type: "info" }));
                        break
                    case 'mark':
                        dispatch(showSnackbar({ message: "Added Marks", type: "info" }));
                        break
                    case 'applyRedactions':
                        dispatch(showSnackbar({ message: "Applied Redactions", type: "info" }));
                        break
                    case 'markError':
                        dispatch(showSnackbar({ message: "Failed to Add Marks", type: "error" }));
                        break
                    case 'applyRedactionsError':
                        dispatch(showSnackbar({ message: "Failed to Apply Redactions", type: "error" }));
                        break
                    case 'loadNlp':
                        dispatch(showSnackbar({ message: "AI Results Loaded", type: "info" }));
                        break
                    default:
                        break
                }
            })
            const iframeDoc = instance.UI.iframeWindow.document;
            instance.UI.iframeWindow.addEventListener('fileDownloaded', () => {
                dispatch(showSnackbar({ message: "Download Completed", type: "info" }));
            })

            instance.UI.annotationPopup.add([{
                type: 'actionButton',
                img: 'icon-operation-redo',
                title: 'Repeat Mark',
                onClick: () => { showModal(AnnotationRepeatPopup, {annotation: annotationManager.getSelectedAnnotations()[0], pageCount: documentViewer.getPageCount()})
                },
            }]);

            let documentEdited = false;
            let savingInProgress = false;
            setIsEdit(false)

            //Helper Methods
            let pageTextCache = new Map<number, string>()
            async function getPageText(pageNum: number): Promise<string> {
                let pageText = pageTextCache.get(pageNum)
                if (pageText === undefined) {
                    pageText = await documentViewer.getDocument().getTextByPageAndRect(pageNum, new instance.Core.Math.Rect(0, 0, 1000, 1000))
                    pageText = pageText.replace(/\n/g, " ")
                    pageTextCache.set(pageNum, pageText)
                }
                return pageText
            }

            function getSortedAnnotations() {
                return annotationManager.getAnnotationsList().sort((a, b) => sortByOrderOfAppearnceInDocument(a, b))
            }

            function getAnnotationsWithoutManualEdits() {
                return getSortedAnnotations().filter(annotation => !annotation.getCustomData("ManuallyEdited"));
            }

            // All redaction ui related info can be found: https://www.pdftron.com/documentation/web/guides/redaction/
            setInstance(instance);
            const { documentViewer, Tools, PDFNet, Annotations, annotationManager, ContentEdit, EventHandler } = instance.Core;
            await PDFNet.initialize();
            instance.UI.createToolbarGroup({name: "Redaction Workflow", dataElementSuffix: 'Multi-Panel',
                children:
                        [{
                            type: 'toolGroupButton',
                            toolGroup: 'redactionTools',
                            dataElement: 'redactionToolGroupButton',
                            title: 'annotation.redact',
                        },
                        {
                            type: 'actionButton',
                            toolGroup: 'pageRedactionTools',
                            dataElement: 'pageRedactionToolGroupButton',
                            title: 'action.redactPages',
                            showColor: 'never',
                            img: 'icon-tool-page-redact',
                            onClick: () => instance.UI.openElements(['pageRedactionModal'])
                        }],
                useDefaultElements: true})
            instance.UI.enableFeatures(['ContentEdit', instance.UI.Feature.MultiTab]);
            instance.UI.enableFadePageNavigationComponent();

            documentViewer.addEventListener('changePageMarginFooter', ({ marginFooter }) => {
                isNaN(marginFooter) ? footerMarginPoints = 0 : footerMarginPoints = marginFooter
            })
            documentViewer.addEventListener('changePageMarginHeader', ({ marginHeader }) => {
                isNaN(marginHeader) ? headerMarginPoints = 0 : headerMarginPoints = marginHeader
            })
            documentViewer.addEventListener('changePageMarginLeft', ({ marginLeft }) => {
                isNaN(marginLeft) ? leftMarginPoints = 0 : leftMarginPoints = marginLeft
            })
            documentViewer.addEventListener('changePageMarginRight', ({ marginRight }) => {
                isNaN(marginRight) ? rightMarginPoints = 0 : rightMarginPoints = marginRight
            })

            let searchTermOnLoad: string | undefined = undefined
            let searchOptionsOnLoad: any | undefined = undefined
            let searchAcrossDocuments: boolean = false

            function handleOpeningGoldStandard() {
                openingGoldStandard = false
                goldStandardFileAnnotations = annotationManager.getAnnotationsList().sort((a, b) => sortByOrderOfAppearnceInDocument(a, b))
                    .filter(annotation => annotation.elementName === 'redact' || annotation.elementName === 'highlight')
                //@ts-ignore
                let comparisonFileTabId = instance.UI.TabManager.getAllTabs()[indexesOfFilesToCompare[0]].id
                instance.UI.TabManager.setActiveTab(comparisonFileTabId)
            }

            function createComparisonReport() {
                const comparisonFileAnnotations = annotationManager.getAnnotationsList().sort((a, b) => sortByOrderOfAppearnceInDocument(a, b))
                    .filter(annotation => annotation.elementName === 'redact' || annotation.elementName === 'highlight')
                const fileName = filterPDFExtension(getDecodedFileName())
                const {comparisonReportArray, inexactMetricsRowsArray, exactMetricsRowsArray} = comparisonReportGenerator(goldStandardFileAnnotations, comparisonFileAnnotations, fileName)
                const fileComparisonReportStorage = fileName + 'ComparisonReport'
                const fileInexactMetricsRowsStorage = fileName + 'InexactMetricsRows'
                const fileExactMetricsRowsStorage = fileName + 'ExactMetricsRows'
                localStorage.setItem(fileComparisonReportStorage, JSON.stringify(comparisonReportArray));
                localStorage.setItem(fileInexactMetricsRowsStorage, JSON.stringify(inexactMetricsRowsArray));
                localStorage.setItem(fileExactMetricsRowsStorage, JSON.stringify(exactMetricsRowsArray));
                window.open('/app/user/docs/' + fileName + '/comparisonReport');

                indexesOfFilesToCompare.shift()
                if (indexesOfFilesToCompare.length === 0) {
                    endComparisonReport()
                } else {
                    //in this case open the next tab to create the report
                    //@ts-ignore
                    let comparisonFileTabId = instance.UI.TabManager.getAllTabs()[indexesOfFilesToCompare[0]].id
                    instance.UI.TabManager.setActiveTab(comparisonFileTabId)
                }
            }

            documentViewer.addEventListener('documentLoaded', async () => {
                console.log('document loaded');
                //Workaround for a bug where not all annotations are loaded. Calling exportAnnotations forces it to
                //load all of them. I have it wait one second before doing that because that seems to fix the few
                //remaining cases. Apryse is aware of this bug, and they're working on a permanent solution.
                const doc = documentViewer.getDocument();
                await doc.getDocumentCompletePromise();
                setTimeout(async () => {
                    await instance.Core.annotationManager.exportAnnotations({fields: true});
                    documentViewer.trigger('remainingAnnotationsLoaded')
                    console.log('downloadRemainingAnnotations called internally');
                    if (loadDocumentForComparisonReport) {
                        if (openingGoldStandard) {
                            handleOpeningGoldStandard()
                        } else {
                            createComparisonReport()
                        }
                    }
                    else if (deduplicateSearchResultsToggle) {
                        resetPageTextCache();
                    }
                }, 1000)

                documentEdited = false;
                instance.UI.setToolbarGroup('toolbarGroup-Multi-Panel');
                //If the user wants to search across all documents, do a search for the same term whenever a new document
                //is opened. If they also want to deduplicate search results, then we need to wait until the annotations
                //are loaded, which is handled below.
                if (!deduplicateSearchResultsToggle) {
                    resetPageTextCache();
                }
            })

            let initialPagesRendered = false
            let redactSearchPatternsLoaded = false
            const handleFinishedInitialLoading = async () => {
                //Save the current file if it's a previous version that we're restoring.
                //Note: Save only works if documentLoaded has fired. I'm assuming that will always be true here
                //because this method is only called after finishedRendering is fired.
                if (isRestoring.current) {
                    await saveFileInPlace(false);
                    isRestoring.current = false;
                }
                hideProgressSpinner()
                loadLockConsentModal(iframeDoc)
                documentViewer.removeEventListener('finishedRendering', handleFinishedRendering)
                removeDuplicateTab();

                let calculateHeight = 97;
                const HeaderToolsContainer = iframeDoc.querySelector(".HeaderToolsContainer") as HTMLElement;
                const Header = iframeDoc.querySelector(".Header") as HTMLElement;
                const TabsHeader = iframeDoc.querySelector(".TabsHeader") as HTMLElement;
                calculateHeight += (+HeaderToolsContainer?.clientHeight + +Header?.clientHeight + +TabsHeader?.clientHeight);
                (document.querySelector(".multipanel") as HTMLElement)!.style.top = calculateHeight + "px";
                fixPositionOfMainContainer()
            }

            //There's a bug where if you open a single document with the MultiTab feature turned on, it will open that
            //document twice. This is a temporary fix until that bug is fixed. Closes all tabs but the first one
            //if only one document is supposed to be open.
            const removeDuplicateTab = () => {
                if (selectedFiles.length === 1 && instance.UI.TabManager.getAllTabs().length > 1) {
                    // @ts-ignore
                    const tabIDs = instance.UI.TabManager.getAllTabs().map(tab => tab.id)
                    for (let i = tabIDs.length - 1; i > 0; i--) {
                        instance.UI.TabManager.deleteTab(tabIDs[i])
                    }
                }
            }

            const fixPositionOfMainContainer = () => {
                const documentContainer  = iframeDoc.querySelector(".document-content-container") as HTMLElement;
                const footer  = iframeDoc.querySelector(".footer") as HTMLElement;
                documentContainer.style.cssText= "width: calc(100% - 370px)";
                footer.style.cssText= "width: calc(100% - 370px)";
            }

            const handleFinishedRendering = (needsMoreRendering: any) => {
                console.log('finished rendering');
                //The value needsMoreRendering is built in pdftron. The webviewer initially loads a few pages(usually 10)
                //We want to enable the UI after these pages are rendered
                if (!needsMoreRendering) {
                    initialPagesRendered = true
                    if (redactSearchPatternsLoaded) {
                        //We enable the UI after redactSearchPatterns are loaded and the initial page rendering is finished
                        handleFinishedInitialLoading()
                    }
                }
            }
            //If we started working in the webviewer before the initial render. we get performance issue. We have added
            // this listener to wait for the initial rendering to finish before enabling the UI
            documentViewer.addEventListener('finishedRendering', handleFinishedRendering)

            let searchResultsByDocument: Map<string, any[]> = new Map();
            documentViewer.addEventListener('searchResultsChanged', (searchResults: any[]) => {
                if (searchResults.length === 0 ) {
                    //This has to be turned off for now because search results get cleared when you open a new document.
                    //searchResultsByDocument.delete(documentViewer.getDocument().getFilename())
                } else {
                    searchResultsByDocument.set(getFileNameFromURL(documentViewer.getDocument().getFilename()) ?? documentViewer.getDocument().getFilename(), searchResults);
                }
                console.log(`search results changed: ${searchResults.length}`)
            })

            documentViewer.addEventListener('updateSearchResultsByDocument', (result) => {
                const searchResults = result.searchResults
                if (!searchResults) {
                    searchResultsByDocument.delete(getFileNameFromURL(documentViewer.getDocument().getFilename()) ?? documentViewer.getDocument().getFilename())
                } else {
                    searchResultsByDocument.set(getFileNameFromURL(documentViewer.getDocument().getFilename()) ?? documentViewer.getDocument().getFilename(), searchResults);
                }
            })

            documentViewer.addEventListener('beforeDocumentLoaded', () => {
                const docName = getFileNameFromURL(documentViewer.getDocument().getFilename()) ?? documentViewer.getDocument().getFilename()
                console.log('before document loaded')
                if (searchResultsByDocument.get(docName)) {
                    try {
                        documentViewer.displayAdditionalSearchResults(searchResultsByDocument.get(docName)!);
                    } catch (e: any) {
                        console.error(e);
                    }
                } else {
                    documentViewer.clearSearchResults();
                }

            })

            documentViewer.addEventListener('documentUnloaded', () => {
                console.log('documentUnloaded')
            })

            let loadDocumentForComparisonReport: boolean = false;
            let openingGoldStandard: boolean = false;
            let goldStandardFileAnnotations: Core.Annotations.Annotation[]
            let indexesOfFilesToCompare: number[] = []

            documentViewer.addEventListener('annotationsLoaded', () => {
                console.log('annotations loaded')
            })

            function resetPageTextCache() {
                pageTextCache = new Map<number, string>()

            }
            const sanitize = async () => {
                showModal(SanitizeModal, {
                    onRemoveAll: () => {
                        commitSanization();
                    },
                    onRemoveSelected: async () => {
                        const input = await commitSanization(['metadata', 'bookmarks', 'comments'], true)
                        //const input = await commitSanization(['metadata', 'bookmarks', 'comments', 'files', 'forms', 'hiddenText', 'hiddenLayers', 'deletedContent', 'linkActions', 'overlappingObjects'], true)
                        showModal(SanitizeRemovalModal, {
                            scanData: input,
                            onSelection: (data: Array<any>) => {
                                commitSanization(data);
                            }
                        })
                    }
                })
            }

            let bulkAdjustMarksArray: Core.Annotations.Annotation[] | { id: any; }[] = [];
            let rectArray: any[] = []
            const bulkAdjustMarks = async () => {
                isBulkMarkAdjustSelected = true;
                if(bulkAdjustMarksArray.length >= 0) {
                    bulkAdjustMarksArray = [];
                    rectArray = [];
                }
                annotationManager.getAnnotationsList().forEach(redaction => {
                    //@ts-ignore
                    if (redaction.markChecked) {
                        const rect = redaction.getRect();
                        // @ts-ignore
                        rectArray.push(rect)
                        //@ts-ignore
                        bulkAdjustMarksArray.push(redaction);
                    }
                })
            }

            const ocrDocument = async () => {
                try{
                    const payload = {
                        filename: getDecodedFileName(),
                        projectId: p.projectID!.toString()
                    }
                    const controller = new AbortController();
                    showProgressSpinner();
                    const response: any = await ocrPDFFIle(payload, controller.signal)
                    if(response && response.message && response.message === 'Processing'){
                        hideProgressSpinner()
                        ocrProgressModalOpen()
                        ocrInterval = setInterval(async ()=>{
                            const isFinished: any = await ocrCompletenessStatusCheck(payload,controller.signal);
                            if(isFinished.status && isFinished.status === 'DONE'){
                                clearInterval(ocrInterval);
                                hideModal()
                                dispatch(showSnackbar({ message: "File OCR scan completed. Please reload the page.", type: "info" }));
                                setTimeout(()=>{
                                    window.location.reload();
                                })
                            }
                            if(isFinished.status && isFinished.status === 'ERROR'){
                                clearInterval(ocrInterval);
                                hideModal()
                                dispatch(showSnackbar({ message: "Error encountered while performing OCR scanning", type: "error" }));
                            }
                        },5000);
                    }
                } catch (e: any) {
                    hideProgressSpinner();
                    dispatch(showSnackbar({ message: e.message, type: "error" }));
                    Sentry.captureException(e);
                    hideProgressSpinner();
                }
                documentEdited = true
                setIsEdit(true)
                trackEditedFiles(instance.UI.TabManager.getActiveTab().id, iframeDoc,'add')
            }
            const openSuccessModal=async (payload?:Record<string, string>)=>{
                showModal(SanitizeSuccessModal, {
                    onSave: async () => {
                        hideModal();
                        const documentViewer = instance.Core.documentViewer;
                        await forceSaveButtonClick(documentViewer, payload);
                    },
                     onRemoveSelected: async () => {
                        setSanitizationPayloadHelper(prev=>({...prev, isNew: false}))
                        const input = await commitSanization(['metadata', 'bookmarks', 'comments'], true)
                        //const input = await commitSanization(['metadata', 'bookmarks', 'comments', 'files', 'forms', 'hiddenText', 'hiddenLayers', 'deletedContent', 'linkActions', 'overlappingObjects'], true)
                        showModal(SanitizeRemovalModal, {
                            scanData: input,
                            onSelection: (data: Array<any>) => {
                                commitSanization(data);
                            }
                       })
                     }
                })
            }


            const commitSanization = async (type = ['metadata', 'bookmarks', 'comments', 'files', 'forms', 'hiddenText', 'hiddenLayers', 'deletedContent', 'linkActions', 'overlappingObjects'], scan = false) => {
                const scanData = {
                    'metadata': 0, 'bookmarks': 0, 'comments': 0, 'files': 0, 'forms': 0, 'hiddenText': 0, 'hiddenLayers': 0, 'deletedContent': 0, 'linkActions': 0, 'overlappingObjects': 0
                }
                if (!scan) {
                    showProgressSpinner();
                }
                const forms = annotationManager.getFieldManager().getFields();
                if (type.indexOf('forms') !== -1) {
                    forms.forEach((annotation) => {
                        annotation.widgets?.forEach((val: any) => {
                            if (val instanceof Annotations.WidgetAnnotation || (val instanceof Annotations.ButtonWidgetAnnotation) || (val instanceof Annotations.CheckButtonWidgetAnnotation) || (val instanceof Annotations.ChoiceWidgetAnnotation) || (val instanceof Annotations.TextWidgetAnnotation)) {
                                scanData['forms'] = ++scanData['forms'];
                            }
                        })
                    })
                }
                let linkCounter = 0;
                const list = annotationManager.getAnnotationsList();
                for (let i = 0; i < list.length; i++) {
                    const keys: string[] = Object.keys(Annotations);
                    keys.filter(val => val.length > 2 && val !== 'Annotation').forEach((val: string) => {
                        try {
                            // @ts-ignore
                            if (list[i] instanceof Annotations[val]) {
                                //console.log(val, i)
                            }
                        }
                        catch (e) {
                            //console.log(e)
                        }
                    })
                    if (type.indexOf('files') !== -1 && (list[i] instanceof Annotations.FileAttachmentAnnotation || list[i] instanceof Annotations.FileAttachmentUtils)) {
                        if (scan) {
                            scanData['files'] = ++scanData['files'];
                        }
                    }
                    if (type.indexOf('comments') !== -1 && (list[i] instanceof Annotations.MarkupAnnotation)) {
                        if (scan) {
                            scanData['comments'] = ++scanData['comments'];
                        }
                    }
                    if (type.indexOf('linkActions') !== -1 && (list[i] instanceof Annotations.Link)) {
                        if (scan) {
                            scanData['linkActions'] = ++scanData['linkActions'];
                        }
                    }
                }
                const backendSanitizationParams = {
                    removeBookmarks: type.indexOf('bookmarks') !== -1 || false,
                    removeMeta: type.indexOf('metadata') !== -1 || false,
                    removeComments: type.indexOf('comments') !== -1 || false
                }

                let doc: any = '';
                if(scan) {
                    const buffer = await instance.Core.documentViewer.getDocument().getFileData({includeAnnotations: true, flags: PDFNet.SDFDoc.SaveOptions.e_linearized});
                    doc = await PDFNet.PDFDoc.createFromBuffer(buffer);
                }
                if (type.indexOf('bookmarks') !== -1) {

                    if (scan) {
                        let firstBookmark = await doc.getFirstBookmark();
                        const scanPromise = new Promise(async (resolve, reject) => {
                            while (firstBookmark && await firstBookmark.isValid()) {
                                const next = await firstBookmark.getNext();
                                if (scan) {
                                    scanData['bookmarks'] = next !== null ? ++scanData['bookmarks'] : scanData['bookmarks'];
                                }
                                firstBookmark = next;
                            }
                            resolve(true);
                        })
                        await scanPromise;
                    }
                }

                if (type.indexOf('metadata') !== -1) {

                    if (scan) {
                        const docInfo = await doc.getDocInfo();
                        const infoData = [];
                        const counter: string[] = []
                        infoData.push(await docInfo.getProducer())
                        infoData.push(await docInfo.getSubject())
                        infoData.push(await docInfo.getTitle())
                        infoData.push(await docInfo.getCreator())
                        infoData.forEach((val) => {
                            if (val) {
                                counter.push(val)
                            }
                        })
                        scanData['metadata'] = counter.length > 0 ? 1: 0;
                        return scanData;
                    }
                }

                // sanitization in backend
                if (!scan && (backendSanitizationParams.removeBookmarks || backendSanitizationParams.removeMeta || backendSanitizationParams.removeComments)) {
                                       try{
                        const payload = {
                            filename: getDecodedFileName(),
                            projectId: p.projectID!.toString(),
                            removeMeta: backendSanitizationParams.removeMeta,
                            removeBookmarks: backendSanitizationParams.removeBookmarks,
                            removeComments: backendSanitizationParams.removeComments,
                            isNew: sanitizationPayloadHelper.isNew
                        }
                        const controller = new AbortController();
                        showProgressSpinner();

                        //The sanitization is not performed locally it is performed on the s3 file and then it is reloaded. The problem is that
                        // this would remove any local changes we have. This is why we save the file to a temporary location then we perfrom the sanitization and reload that document.
                        const temporarySaveResponse = await temporarySaveLocalFile()
                        if (temporarySaveResponse===undefined) {
                            hideProgressSpinner()
                            dispatch(showSnackbar({message: "Error Saving local changes!", type: "error"}));
                            console.log("failed to save local changes")
                            return
                        }
                        const response: any = await sanitizePDFFIle(payload, controller.signal)
                        if(response && response.status && response.status === 'PROCESSING'){
                            ocrProgressModalOpen("Sanitization is processing")
                            ocrInterval = setInterval(async ()=>{
                                const isFinished: Record<string, string> = await sanitizeCompletenessStatusCheck({filename: payload.filename, projectId: payload.projectId},controller.signal);
                                if(isFinished.status && isFinished.status === 'DONE'){
                                    clearInterval(ocrInterval);
                                    hideModal()
                                    dispatch(showSnackbar({ message: "File sanitization is completed.", type: "info" }));
                                    zoomLevel = instance.UI.getZoomLevel()
                                   if(isFinished.s3url) {
                                        instance.UI.loadDocument(isFinished.s3url)
                                    }
                                    setTimeout(async () => {
                                        setSanitizedData({[payload.filename]: ''})
                                        await openSuccessModal({filename:payload.filename,projectId: payload.projectId, tempPdfBucket: isFinished.tempPdfBucket, tempPdfKey: isFinished.tempPdfKey})
                                        if(zoomLevel !== null) {
                                            instance.UI.setZoomLevel(zoomLevel)
                                        }
                                        hideProgressSpinner();
                                        // I've put a wait time of 8s, so pdf gets loaded and annotations gets applied again.
                                    }, 8000)
                                }
                            },5000);
                        }
                    } catch (e: any) {
                        hideProgressSpinner();
                        dispatch(showSnackbar({ message: e.message, type: "error" }));
                        Sentry.captureException(e);
                    }


                }
                if (!scan && (!backendSanitizationParams.removeBookmarks && !backendSanitizationParams.removeMeta && !backendSanitizationParams.removeComments)) {
                    hideProgressSpinner();
                }
                documentEdited = true;
                setIsEdit(true)
                trackEditedFiles(instance.UI.TabManager.getActiveTab().id, iframeDoc,'add')
            }

            function searchListener(searchValue: any, options: any, results: any) {
                searchTermOnLoad = searchValue
                searchOptionsOnLoad = options
            }
            instance.UI.addSearchListener(searchListener);

            let deduplicateSearchResultsToggle = false;
            let logs: log[] = []

            function isQuadFullyCovered(quad: any, annotationsOnPage: any[]): boolean {
                // (x1,y1) is the upper left corner and (x3,y3) is the bottom right
                const points: { x1: number, y1: number, x3: number, y3: number } = quad.getPoints()
                const searchRect = { x1: points.x1, y1: points.y1, x2: points.x3, y2: points.y3 } as Rectangle
                for (let annot of annotationsOnPage) {
                    for (let quad of annot.Quads) {
                        const annotRect = { x1: quad.x1, y1: quad.y1, x2: quad.x3, y2: quad.y3 } as Rectangle
                        if (contains(annotRect, searchRect)) {
                            return true
                        }
                    }
                }
                return false
            }

            function rectToRectangle(rect: any) {
                const {x1, y1, x2, y2} = rect
                return {x1, y1, x2, y2} as Rectangle
            }

            function quadToRectangle(quad: any) {
                const quadPoints: { x1: number, y1: number, x3: number, y3: number } = quad.getPoints()
                return  {
                    x1: quadPoints.x1,
                    y1: quadPoints.y1,
                    x2: quadPoints.x3,
                    y2: quadPoints.y3
                } as Rectangle
            }

            async function getPageSearchRegionRectangle(width: number, height: number, pageRotation: number) {
                let searchRegionRect
                let ROTATE_0 = 0;
                let ROTATE_90 = 90;
                let ROTATE_180 = 180;
                let ROTATE_270 = 270;
                switch(pageRotation) {
                    case ROTATE_0:
                        searchRegionRect = new PDFNet.Rect(getLeftMarginPoints(width),
                            getHeaderPoints(height),
                            width-getRightMarginPoints(width),
                            height - getFooterPoints(height))
                        break
                    case ROTATE_180:
                        searchRegionRect = new PDFNet.Rect(getRightMarginPoints(width),
                            getFooterPoints(height),
                            width-getLeftMarginPoints(width),
                            height - getHeaderPoints(height))
                        break
                    case ROTATE_90:
                        searchRegionRect = new PDFNet.Rect(getHeaderPoints(height),
                            getRightMarginPoints(width),
                            height-getFooterPoints(height),
                            width - getLeftMarginPoints(width))
                        break
                    case ROTATE_270:
                        searchRegionRect = new PDFNet.Rect(getFooterPoints(height),
                            getLeftMarginPoints(width),
                            height-getHeaderPoints(height),
                            width - getRightMarginPoints(width))
                        break
                    default:
                        searchRegionRect = new PDFNet.Rect(getLeftMarginPoints(width),
                            getHeaderPoints(height),
                            width-getRightMarginPoints(width),
                            height - getFooterPoints(height))
                        break
                }
                return rectToRectangle(searchRegionRect)
            }

            function getHeaderPoints(height: number) {
                return (headerMarginPoints / 100.0) * height;
            }

            function getRightMarginPoints(width: number) {
                return (rightMarginPoints / 100.0) * width;
            }

            function getFooterPoints(height: number) {
                return (footerMarginPoints / 100.0) * height;
            }

            function getLeftMarginPoints(width: number) {
                return (leftMarginPoints / 100.0) * width;
            }

            const replaceAnnotationTextAspose = async (annotations: any[], annotationsToRedact: any[]) => {
                const annotationMap = {}
                let hasStuffToReflow = false;
                annotations.forEach((annotation) => {
                    //@ts-ignore
                    if (annotation.transformChecked && annotation.getCustomData(REPLACEMENT_TEXT_COLUMN) && annotation.getCustomData(REPLACEMENT_TEXT_COLUMN) !== RETAIN_KEYWORD) {
                        // @ts-ignore
                        annotationMap[annotation.Id] = annotation.getCustomData(REPLACEMENT_TEXT_COLUMN)
                        hasStuffToReflow = true;
                    }
                })

                if (!hasStuffToReflow) {
                    await finishReplacement(annotations, annotationsToRedact);
                    hideProgressSpinner();
                    return;
                }

                console.log('transforming text')
                try{
                    const payload = {
                        filename: getDecodedFileName(),
                        projectId: p.projectID!.toString(),
                        isNew: sanitizationPayloadHelper.isNew,
                        annotationMap: annotationMap
                    }
                    const controller = new AbortController();
                    showProgressSpinner();
                    ocrProgressModalOpen("Transforming annotations and reflowing text")
                    const temporarySaveResponse = await temporarySaveLocalFile()
                    if (temporarySaveResponse === undefined) {
                        hideProgressSpinner()
                        dispatch(showSnackbar({message: "Error Saving local changes!", type: "error"}));
                        return;
                    }
                    console.log('sending for text reflow')
                    const response: any = await onTextReflow(payload, controller.signal)
                    if(response && response.status && response.status === 'PROCESSING'){
                        ocrInterval = setInterval(async ()=>{
                            const isFinished: Record<string, any> = await reflowCompletenessStatusCheck({filename: payload.filename, projectId: payload.projectId},controller.signal);
                            if(isFinished.status && isFinished.status === 'DONE'){
                                clearInterval(ocrInterval);
                                zoomLevel = instance.UI.getZoomLevel()
                                if(isFinished.s3url) {
                                    instance.UI.loadDocument(isFinished.s3url)
                                }

                                const exitTextReflowing = async () => {
                                    if (zoomLevel !== null) {
                                        instance.UI.setZoomLevel(zoomLevel)
                                    }
                                    console.log(isFinished.errorAnnotations)
                                    await finishReplacement(annotations, annotationsToRedact, isFinished.errorAnnotations);
                                    hideProgressSpinner();
                                    hideModal()
                                    if (isFinished.errorAnnotations && isFinished.errorAnnotations.length > 0) {
                                        dispatch(showSnackbar({
                                            message: "Some annotation could not be transformed. See Transform panel",
                                            type: "error"
                                        }));
                                    } else {
                                        dispatch(showSnackbar({message: "Text reflow is completed.", type: "info"}));
                                    }
                                    documentViewer.removeEventListener('remainingAnnotationsLoaded', exitTextReflowing)
                                }

                                documentViewer.addEventListener('remainingAnnotationsLoaded', exitTextReflowing)
                            }
                            if(isFinished.status && isFinished.status === 'ERROR') {
                                hideProgressSpinner();
                                hideModal()
                                clearInterval(ocrInterval);
                                dispatch(showSnackbar({message: "Text reflow processing failed", type: "error"}));
                                return;
                            }
                        },5000);
                    }
                } catch (e: any) {
                    hideProgressSpinner();
                    hideModal()
                    dispatch(showSnackbar({ message: e.message, type: "error" }));
                    Sentry.captureException(e);
                }
            }

            const replaceAnnotationTextApryse = async (annotations: any[]) => {
                const doc = await documentViewer.getDocument().getPDFDoc();
                // Create a map where each key is a page number, and each value is a list of annotations on that page
                const annotationsByPage: Map<number, any[]> = annotations.reduce((map: Map<number, any[]>, annotation: any) => {
                    const pageNumber = annotation.getPageNumber();
                    return map.set(pageNumber, (map.get(pageNumber) || []).concat(annotation));
                }, new Map<number, any[]>());


                const txt = await PDFNet.TextExtractor.create();
                annotationsByPage.forEach(async (annotations: any[], pageNumber: number) => {
                    // Run PDFNet methods with memory management
                    await PDFNet.runWithCleanup(async () => {
                        // lock the document before a write operation
                        // runWithCleanup will auto unlock when complete
                        await doc.lock();
                        const replacer = await PDFNet.ContentReplacer.create();
                        const page = await doc.getPage(pageNumber);

                        for (let i = 0; i < annotationsByPage.get(pageNumber)!.length; i++) {
                            const annotation = annotationsByPage.get(pageNumber)![i];
                            //If the replacement text is the same as the original, no need to replace.
                            if (annotation.getCustomData(REPLACEMENT_TEXT_COLUMN) === RETAIN_KEYWORD) {
                                continue;
                            }
                            const replacementTextsByLine = annotation.getCustomData(REPLACEMENT_TEXT_COLUMN).split('\n')
                            //If you run into a case where the last letter inside the rect is not deleted, consider increasing
                            //this value. But be careful because there's a danger of deleting text outside the rect.
                            const extensionTolerance = 0.1
                            for (let i=0;i<annotation.Quads.length;i++) {
                                const replacementText = (replacementTextsByLine.length>i) ? replacementTextsByLine[i] : ''
                                const quad = annotation.Quads[i]
                                const height = annotation.getHeight();
                                const firstPoint = documentViewer.getDocument().getPDFCoordinates(pageNumber, quad.x1, Math.min(quad.y3, quad.y1));//sometimes other applications swap y1 and y3
                                const secondPoint = documentViewer.getDocument().getPDFCoordinates(pageNumber, quad.x2 + extensionTolerance, Math.max(quad.y3, quad.y1));
                                //Make the rectangle a little shorter so that it doesn't delete text on the lines above and below it.
                                const rect = new PDFNet.Rect(firstPoint.x, secondPoint.y , secondPoint.x, firstPoint.y);
                                const rectheight = await rect.height()

                                txt.begin(page, rect, PDFNet.TextExtractor.XMLOutputFlags.e_output_bbox)
                                //we use the text in the box to better place the replacement text
                                const line = await txt.getFirstLine()
                                const containedTextBbox = await line.getBBox()
                                const bboxHeight = await containedTextBbox.height()
                                let topPoint
                                let bottomPoint

                                //We might have to change these percentage height increments to see which one works better with most documents
                                if (rectheight<bboxHeight) { //when the size of the contained text is almost as big as the
                                    // annotation rect. We see some alighment issues where the replacement text appears a little low. This is why we use different
                                    // values to try to make it go up a little bit
                                    topPoint = firstPoint.y
                                    bottomPoint=secondPoint.y + (rectheight * 0.2) // adding this 20% seems to improve the alignment
                                } else {
                                    topPoint =Math.min((firstPoint.y - (rectheight * 0.08)), containedTextBbox.y2)
                                    bottomPoint = Math.max(secondPoint.y + (rectheight * 0.2), containedTextBbox.y1+0.2*bboxHeight)
                                }

                                const replacementRect = new PDFNet.Rect(firstPoint.x, bottomPoint, secondPoint.x, topPoint);
                                //This deletes all the text in the rectangle and adds the replacement text.
                                await replacer.addText(replacementRect, replacementText);
                            }
                        }
                        await replacer.process(page);
                    }, 'Real Life Sciences, LLC (rlsciences.com):OEM:RL Protect Docs::B+:AMS(20240530):57A5A3620477C80A0360B13AC9A273F260617FEB89B17A92FB446D82ED34C3AE42B231F5C7');
                })
                // clear the cache (rendered) data with the newly updated document
                documentViewer.refreshAll();
                // Update viewer to render with the new document
                documentViewer.updateView();
                // Refresh searchable and selectable text data with the new document
                documentViewer.getDocument().refreshTextData();
                hideProgressSpinner();
            }

            const replaceAnnotationsInPlace = async (annotations: any[], source: string) => {
                showProgressSpinner();
                let annotationsToRedact: any[] = [];
                let annotationsToTransform: any[] = [];
                annotations.forEach(annotation => {
                    if (annotation.getCustomData(REPLACEMENT_TEXT_COLUMN) === REDACTION_KEYWORD) {
                        annotationsToRedact.push(annotation);
                    } else if (annotation.getCustomData(REPLACEMENT_TEXT_COLUMN)) {
                        annotationsToTransform.push(annotation);
                    }
                })
                if (source === 'transform') {
                    replaceAnnotationTextAspose(annotationsToTransform, annotationsToRedact)
                } else {
                    replaceAnnotationTextApryse(annotationsToTransform)
                }
            }

            const finishReplacement = async (annotationsToTransform: any[], annotationsToRedact: any[], errorAnnotations?: string[]) => {
                const errorAnnotationSet = new Set(errorAnnotations);
                const transformedAnnotations = [];
                for (let oldAnnotation of annotationsToTransform) {
                    const newAnnotation = annotationManager.getAnnotationById(oldAnnotation.Id);
                    if (newAnnotation && (!errorAnnotations || !errorAnnotationSet.has(oldAnnotation.Id))) {
                        transformedAnnotations.push(newAnnotation);
                    } else if (errorAnnotations && errorAnnotationSet.has(oldAnnotation.Id)) {
                        console.log('error transforming this annotation')
                        console.log(oldAnnotation);
                    } else {
                        console.log('This annotation got lost:')
                        console.log(oldAnnotation);
                        console.log(newAnnotation);
                    }
                }
                const annotationsToRedactOnNewDoc = []
                for (let oldAnnotation of annotationsToRedact) {
                    const newAnnotation = annotationManager.getAnnotationById(oldAnnotation.Id);
                    if (newAnnotation) {
                        annotationsToRedactOnNewDoc.push(newAnnotation);
                    }
                }

                const highlights = getHighlightsOverAnnotations(transformedAnnotations);
                annotationManager.deleteAnnotations(transformedAnnotations);
                annotationManager.addAnnotations(highlights)
                await annotationManager.applyRedactions(annotationsToRedactOnNewDoc)
            }

            let doNotShowSelectCategoryFlag = false//when reverting highlights, the generated redaction should preserve the category not be selected by the select category modal
            const revertHighlightsInPlace = async (annotations: any[]) => {
                //TODO change this to aspose. May need to change FMS to handle highlights as well as redactions
                replaceAnnotationTextApryse(annotations)
                const redactionAnnotations = getRedactionMarksOverAnnotations(annotations)
                annotationManager.deleteAnnotations(annotations)
                doNotShowSelectCategoryFlag = true
                annotationManager.addAnnotations(redactionAnnotations)
                doNotShowSelectCategoryFlag = false
            }

            const getHighlightsOverAnnotations = (annotations: any[]) => {
                let highlights = []
                for (const annotation of annotations) {
                    const highlight = new Annotations.TextHighlightAnnotation({
                        PageNumber: annotation.getPageNumber(),
                        Quads: [annotation.Quads],
                    });
                    highlight.PageNumber = annotation.PageNumber;
                    highlight.Color = new Annotations.Color(144, 238, 144, 1.0)
                    highlight.Opacity = 1.0;
                    highlight.Quads = annotation.Quads;
                    highlight.setContents(annotation.getCustomData('trn-annot-preview'));
                    highlight.setCustomData('author', loginInfo?.tenant?.user?.name || 'Unknown')
                    highlight.setCustomData(REPLACEMENT_TEXT_COLUMN, annotation.getCustomData(REPLACEMENT_TEXT_COLUMN))
                    highlight.setCustomData(HIGHLIGHT_TEXT, annotation.getCustomData(REPLACEMENT_TEXT_COLUMN))
                    highlight.setCustomData(PATIENT_ID_COLUMN, annotation.getCustomData(PATIENT_ID_COLUMN))
                    highlight.setCustomData(START_DATE_COLUMN, annotation.getCustomData(START_DATE_COLUMN))
                    highlight.Author = annotation.Author;
                    // @ts-ignore
                    highlight.type = annotation.type;
                    //The following custom data helps in making sure that when we revert, the mark will have the same style it had before
                    highlight.setCustomData('RedactionOverlayText', annotation.OverlayText)
                    highlight.setCustomData('RedactionFillColor', annotation.FillColor)
                    highlight.setCustomData('RedactionTextColor', annotation.TextColor)
                    highlight.setCustomData('RedactionStrokeColor', annotation.StrokeColor)
                    highlight.setCustomData('RedactionFontSize', annotation.FontSize)
                    highlight.setCustomData('RedactionOpacity', annotation.Opacity)
                    highlight.setCustomData('RedactionStrokeThickness', annotation.StrokeThickness)
                    highlight.setCustomData('RedactionTextAlign', annotation.TextAlign)
                    highlights.push(highlight)
                }
                return highlights
            }

            const getRedactionMarksOverAnnotations = (annotations: any[]) => {
                let redactionAnnotations = []
                for (const annotation of annotations) {
                    const redactionAnnotation = new Annotations.RedactionAnnotation({
                        PageNumber: annotation.getPageNumber(),
                        Quads: [annotation.Quads],
                    });
                    redactionAnnotation.PageNumber = annotation.PageNumber;
                    redactionAnnotation.Opacity = 1.0;
                    redactionAnnotation.Quads = annotation.Quads;
                    redactionAnnotation.setContents(annotation.getCustomData('trn-annot-preview'));
                    redactionAnnotation.setCustomData('trn-annot-preview', annotation.getCustomData('trn-annot-preview'));
                    redactionAnnotation.setCustomData('author', loginInfo?.tenant?.user?.name || 'Unknown')
                    redactionAnnotation.setCustomData(REPLACEMENT_TEXT_COLUMN, annotation.getCustomData("RevertedReplacement"))
                    redactionAnnotation.Author = annotation.Author;
                    // @ts-ignore
                    redactionAnnotation.type = annotation.type;
                    //We restore the same mark style that was used
                    let FillColorObject = JSON.parse(annotation.getCustomData('RedactionFillColor'))
                    let TextColorObject = JSON.parse(annotation.getCustomData('RedactionTextColor'))
                    let StrokeColorObject = JSON.parse(annotation.getCustomData('RedactionStrokeColor'))
                    // @ts-ignore
                    redactionAnnotation.OverlayText = annotation.getCustomData('RedactionOverlayText')
                    redactionAnnotation.FillColor['R'] = FillColorObject["R"];
                    redactionAnnotation.FillColor['G'] = FillColorObject['G'];
                    redactionAnnotation.FillColor['B'] = FillColorObject['B'];
                    redactionAnnotation.FillColor['A'] = FillColorObject['A'];
                    // @ts-ignore
                    redactionAnnotation.TextColor['R'] = TextColorObject['R'];
                    // @ts-ignore
                    redactionAnnotation.TextColor['G'] = TextColorObject['G'];
                    // @ts-ignore
                    redactionAnnotation.TextColor['B'] = TextColorObject['B'];
                    // @ts-ignore
                    redactionAnnotation.TextColor['A'] = TextColorObject['A'];
                    redactionAnnotation.StrokeColor['R'] = StrokeColorObject['R'];
                    redactionAnnotation.StrokeColor['G'] = StrokeColorObject['G'];
                    redactionAnnotation.StrokeColor['B'] = StrokeColorObject['B'];
                    redactionAnnotation.StrokeColor['A'] = StrokeColorObject['A'];
                    // @ts-ignore
                    redactionAnnotation.FontSize = annotation.getCustomData('RedactionFontSize');
                    redactionAnnotation.Opacity = annotation.getCustomData('RedactionOpacity');
                    redactionAnnotation.StrokeThickness = annotation.getCustomData('RedactionStrokeThickness');
                    // @ts-ignore
                    redactionAnnotation.TextAlign = annotation.getCustomData('RedactionTextAlign');
                    redactionAnnotations.push(redactionAnnotation)
                }
                return redactionAnnotations
            }

            //What this method does is replace any instance of 'General Weakness' with 'AE'. This does not work for our
            //purposes because we don't always want to replace every instance of a text, just the text inside an annotation.
            //The other method can replace the text in an annotation, but it does not do reflowing. I tried to string together
            //These two methods by first replacing the text in an annotation with a placeholder value like [X] and then here
            //replacing all instances of [X] with the final replacement text. That does NOT work because once the text in
            //an annotation is replaced, it's created inside a new paragraph object so this method can't reflow the text around it.
            const reflow = async () => {
                const doc = await documentViewer.getDocument().getPDFDoc();
                await PDFNet.runWithCleanup(async () => {
                    await doc.lock();
                    const replacer = await PDFNet.ContentReplacer.create();
                    const page = await doc.getPage(1);
                    //This is [] by default. It's supposed to allow these to be set to empty strings but it's not working.
                    await replacer.setMatchStrings(' ', ' ')
                    //There also seems to be a problem with multiword phrases.
                    await replacer.addString('General Weakness', 'AE');
                    await replacer.process(page)
                }, 'Real Life Sciences, LLC (rlsciences.com):OEM:RL Protect Docs::B+:AMS(20240530):57A5A3620477C80A0360B13AC9A273F260617FEB89B17A92FB446D82ED34C3AE42B231F5C7');

                // clear the cache (rendered) data with the newly updated document
                documentViewer.refreshAll();
                // Update viewer to render with the new document
                documentViewer.updateView();
                // Refresh searchable and selectable text data with the new document
                documentViewer.getDocument().refreshTextData();
            }

            const commentAction = async () => {
                if (instance.UI.isElementOpen('notesPanel')) {
                    instance.UI.closeElements(['notesPanel']);
                    iframeDoc.querySelector<HTMLElement>('[data-element="toggleComment"]')!.classList.remove('active')
                    document.querySelector(".multipanel")!.classList.remove("close")
                    setTimeout(()=>fixPositionOfMainContainer(), 0);
                } else {
                    instance.UI.openElements(['notesPanel']);
                    iframeDoc.querySelector<HTMLElement>('[data-element="toggleComment"]')!.classList.add('active');
                    document.querySelector(".multipanel")!.classList.add("close")
                }
            }

            const reflowButton = {
                type: 'actionButton',
                img: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ff0000"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>',
                onClick: async () => {
                    await reflow()
                },
                dataElement: 'alertButton',
                hidden: ['mobile']
            };

            const commentButton = {
                type: 'actionButton',
                img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:#abb0c4;}</style></defs><title>icon - header - chat - line</title><path class="cls-1" d="M16.39,6.12h-12a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2h1v3.76L6.9,21l4.76-2.85h4.73a2,2,0,0,0,2-2v-8A2,2,0,0,0,16.39,6.12Zm0,10H11.11L7.39,18.35V16.12h-3v-8h12Zm6-12v9h-2v-9h-13v-2h13A2,2,0,0,1,22.39,4.12Z"></path></svg>',
                onClick: async () => {
                    await commentAction()
                },
                dataElement: 'toggleComment'
            };

            const deduplicateButton = {
                type: 'statefulButton',
                initialState: 'Dupes',
                states: {
                    Dupes: {
                        img: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>',
                        onClick: (update: (newState: any) => void) => {
                            deduplicateSearchResultsToggle = true
                            console.log(`deduplicate search results? ${deduplicateSearchResultsToggle}`)
                            update('NoDupes');
                        },
                        title: "Show all search results",
                    },
                    NoDupes: {
                        img: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect x="0" y="0" width="24" height="24" rx="5" fill="#111122"/></g><path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>',
                        onClick: (update: (newState: any) => void) => {
                            deduplicateSearchResultsToggle = false
                            console.log(`deduplicate search results? ${deduplicateSearchResultsToggle}`)
                            update('Dupes');
                        },
                        title: "Don't show search results in existing annotations"
                    }
                },
                dataElement: 'deduplicateButton'
            };

            let replacementTextInput = document.createElement('input');
            replacementTextInput.type = 'text';
            replacementTextInput.id = 'unique_id_1';
            // @ts-ignore
            replacementTextInput.style.width = '500px';

            let bulkChangeOverlayFontSizeDiv = document.createElement('div');
            let fontSizeText = document.createElement('p');
            fontSizeText.innerText = 'Font Size: '
            fontSizeText.style.flexDirection='column'
            let selectFont = document.createElement('select');
            fontSizeValues.map((fontSizeValue) => {
                let option = document.createElement('option');
                option.value = fontSizeValue;
                option.text = fontSizeValue;
                selectFont.add(option);
            })
            selectFont.value='9pt'
            bulkChangeOverlayFontSizeDiv.appendChild(fontSizeText)
            bulkChangeOverlayFontSizeDiv.appendChild(selectFont)
            bulkChangeOverlayFontSizeDiv.style.display='flex'
            bulkChangeOverlayFontSizeDiv.style.justifyContent='space-between'
            bulkChangeOverlayFontSizeDiv.style.width='130px'
            let autoSizeOverlayFontText = document.createElement('p');
            autoSizeOverlayFontText.innerText = 'auto-size to fit: '
            let autoSizeOverlayFontCheckBox = document.createElement('input');
            autoSizeOverlayFontCheckBox.type='checkbox'
            let bulkAutoSizeOverlayFontDiv = document.createElement('div');
            bulkAutoSizeOverlayFontDiv.appendChild(autoSizeOverlayFontText)
            bulkAutoSizeOverlayFontDiv.appendChild(autoSizeOverlayFontCheckBox)
            bulkAutoSizeOverlayFontDiv.style.display='flex'
            bulkAutoSizeOverlayFontDiv.style.justifyContent='space-between'
            bulkAutoSizeOverlayFontDiv.style.width='110px'

            const bulkChangeOverlayFontModal = {
                dataElement: 'bulkChangeOverlayFontModal',
                header: {
                    title: 'Edit Mark Font',
                    className: 'ReplacementTextModal',
                },
                body: {
                    children: [bulkChangeOverlayFontSizeDiv, bulkAutoSizeOverlayFontDiv],
                    style: {}
                },
                footer: {
                    className: 'myCustomModal-footer footer',
                    children: [
                        {
                            title: 'Cancel',
                            button: true,
                            style: {},
                            className: 'modal-button cancel-form-field-button',
                            onClick: () => { instance.UI.closeElements([bulkChangeOverlayFontModal.dataElement]) }
                        },
                        {
                            title: 'Change Font',
                            button: true,
                            style: {},
                            className: 'modal-button confirm ok-btn',
                            onClick: () => {
                                instance.UI.closeElements([bulkChangeOverlayFontModal.dataElement]);
                                annotationManager.getAnnotationsList().forEach(redaction => {
                                    //@ts-ignore
                                   if (redaction.markChecked) {
                                       //@ts-ignore
                                       redaction.FontSize = selectFont.value
                                       if (autoSizeOverlayFontCheckBox.checked) {
                                           adjustFontSizeToFit(redaction);
                                       }
                                   }
                                })
                            }
                        },
                    ]
                }
            }

            let updateFontSizeOfSelectedMarksText = document.createElement('p');
            updateFontSizeOfSelectedMarksText.innerText = 'Do you want to apply these changes to all selected Marks?'
            let fontSizeForSelectedMarks: string

            const updateFontSizeOfSelectedMarks = {
                dataElement: 'updateFontSizeOfSelectedMarks',
                body: {
                    children: [updateFontSizeOfSelectedMarksText],
                    style: {}
                },
                footer: {
                    className: 'myCustomModal-footer footer',
                    children: [
                        {
                            title: 'No',
                            button: true,
                            style: {},
                            className: 'modal-button cancel-form-field-button',
                            onClick: () => { instance.UI.closeElements([updateFontSizeOfSelectedMarks.dataElement]) }
                        },
                        {
                            title: 'Yes',
                            button: true,
                            style: {},
                            className: 'modal-button confirm ok-btn',
                            onClick: () => {
                                instance.UI.closeElements([updateFontSizeOfSelectedMarks.dataElement]);
                                annotationManager.getAnnotationsList().forEach(redaction => {
                                    //@ts-ignore
                                    if (redaction.markChecked) {
                                        //@ts-ignore
                                        redaction.FontSize = fontSizeForSelectedMarks
                                        adjustFontSizeToFit(redaction);
                                    }
                                })
                            }
                        },
                    ]
                }
            }

            //@ts-ignore
            instance.UI.addCustomModal(bulkChangeOverlayFontModal);
            //@ts-ignore
            instance.UI.addCustomModal(updateFontSizeOfSelectedMarks);

            const afterSelectFontTypeAndSize = async (fontType: string, fontSize: string) => {
                //@ts-ignore
                let highlights = annotationManager.getAnnotationsList().filter(redaction => redaction.highlightChecked)
                const doc = await documentViewer.getDocument().getPDFDoc();

                const writer = await PDFNet.ElementWriter.create();
                const reader = await PDFNet.ElementReader.create();
                // Create a map where each key is a page number, and each value is a list of annotations on that page
                const annotationsByPage: Map<number, any[]> = highlights.reduce((map: Map<number, any[]>, annotation: any) => {
                    const pageNumber = annotation.getPageNumber();
                    return map.set(pageNumber, (map.get(pageNumber) || []).concat(annotation));
                }, new Map<number, any[]>());

                //retrieve the font from bucket
                const fontRequest = await getFontUrl(fontType)
                const fontBlob = await axios.get(fontRequest.fontUrl,
                    {
                        responseType: 'blob'
                    })
                let currentFontBuffer = await fontBlob.data.arrayBuffer();
                const textEncoder = new TextEncoder();
                //We must embed the font in the document to be able to use it.
                let currentFont = await PDFNet.Font.createTrueTypeFontWithBuffer(doc, currentFontBuffer, true)

                // We use the ElementWriter and ElementReader to rewrite the pages. When we find the Element that is in contained
                // in the highlight we set its font to the new font style.
                for (let pageNumber of Array.from(annotationsByPage.keys())) {
                    let pageAnnotations = annotationsByPage.get(pageNumber)
                    const page = await doc.getPage(pageNumber)
                    const pageCropBox = await page.getCropBox()
                    const pageHeight = await pageCropBox.height()
                    let highlightElementMap = new Map<any, any>();
                    reader.beginOnPage(page);
                    writer.beginOnPage(page, PDFNet.ElementWriter.WriteMode.e_replacement, false);
                    for (let element = await reader.next(); element !== null; element = await reader.next()) {
                        const elementType = await element.getType();
                        switch (elementType) {
                            case PDFNet.Element.Type.e_text:
                                let bBox = await element.getBBox();
                                for (let highlight of pageAnnotations!) {
                                    if (rectContainsElement(highlight.getRect(), bBox, pageHeight)) {
                                        const text = await element.getTextString()
                                        if (text === highlight.getCustomData(HIGHLIGHT_TEXT) || highlight.getCustomData(HIGHLIGHT_TEXT).startsWith(text)) {
                                            let gs = await element.getGState();
                                            //Set the element to the new font.
                                            await gs.setFont(currentFont, parseInt(fontSize));
                                            const uint8Array = textEncoder.encode(text);
                                            await element.setTextData(uint8Array)//This fixes a strange error that I was having. for some reason
                                            //when I set the new font. The text adds some strange special characters. The solution I did is that we get
                                            //the text before these strange characters are added, and then set the text data to that.
                                            await element.updateTextMetrics()//to update the text box dimensions to the new values
                                            let newBBox = await element.getBBox();
                                            highlightElementMap.set(highlight, newBBox);
                                        }
                                    }
                                }
                                await writer.writeElement(element);
                                break;
                            default:
                                writer.writeElement(element);
                        }
                    }
                    //update the highlights
                    for (let highlight of pageAnnotations!) {
                        const newBBox = highlightElementMap.get(highlight)
                        let nquads = new instance.Core.Math.Quad(newBBox.x1, pageHeight-newBBox.y1 , newBBox.x2, pageHeight-newBBox.y1, newBBox.x2, pageHeight-newBBox.y2, newBBox.x1, pageHeight-newBBox.y2)
                        highlight.Quads = [nquads]
                    }
                    writer.end();
                    reader.end();

                    // clear the cache (rendered) data with the newly updated document
                    documentViewer.refreshAll();
                    // Update viewer to render with the new document
                    documentViewer.updateView();
                    // Refresh searchable and selectable text data with the new document
                    documentViewer.getDocument().refreshTextData();
                }
            }
            const selectFontTypeAndSizeModal = createSelectFontTypeAndSizeModal(fontNames, fontSizeOptions, instance, afterSelectFontTypeAndSize)
            instance.UI.addCustomModal(selectFontTypeAndSizeModal)

            const onCloseEditMarkModal = () => {
                instance.UI.closeElements(['editMarkModal'])
                for (let hotkey in instance.UI.hotkeys.Keys) {//we add back all the hotkeys because we closed them when we opened the modal
                    instance.UI.hotkeys.on(hotkey)
                }
            }
            const afterEditMark = async (patientID: string, replacementText: string, startDate: string) => {
                onCloseEditMarkModal()
                annotationManager.getSelectedAnnotations().forEach((redaction) => {
                    //@ts-ignore
                    if (redaction.elementName === 'redact') {
                        //set the values
                        redaction.setCustomData(PATIENT_ID_COLUMN, patientID)
                        redaction.setCustomData(REPLACEMENT_TEXT_COLUMN, replacementText)
                        redaction.setCustomData(START_DATE_COLUMN, startDate)
                    }
                })
                annotationManager.trigger('transformsLoaded')
            }
            const editMarkModal = createEditMarkModal(onCloseEditMarkModal, afterEditMark)
            instance.UI.addCustomModal(editMarkModal)


            const getDecodedFileName = () : string => {
                const name = documentViewer?.getDocument()?.getFilename().split('?')[0]
                return decodeURIComponent(name)
            }

            const saveFileInPlace = async (showModalFlag : boolean) => {
                const filename = (closeBtn ? closeBtn.dataset.file : localStorage.getItem('blobData') !== null ? await retreieveBlobData('filename') : getDecodedFileName()) || instance.UI.TabManager.getActiveTab().options.filename
                console.log(filename)
                if(showModalFlag) {
                    const tag = await getFileTagByProjectIdAndFileName(p.projectID, filename)
                    let updatedTag = tag.tag;
                    await showModal(UpdateFileTagModal, {
                        projectId: p.projectID,
                        fileName: filename,
                        fileId: fileNameAndIdMap.get(filename),
                        tag: updatedTag,
                        updateTag: async (tag) => {
                            try {
                                const updatedData = undefined
                                updatedTag = tag;
                                await saveFile(filename, true, false, updatedData, updatedTag)
                            } catch {

                            }
                        }
                    })
                }
                else {
                    await saveFile(closeBtn ? closeBtn.dataset.file : getDecodedFileName(), true, false,undefined, p.tag)
                }
            }

            instance.Core.documentViewer.addEventListener('saveFile', () => saveFileInPlace(true));

            const stopSaving = () => {
                hideProgressSpinner();
                savingInProgress = false;
                window.dispatchEvent(new Event('processEnded'))
            }

            const temporarySaveLocalFile = async (): Promise<string | undefined> => {
                return saveFile(getDecodedFileName(), true, true )
            }

            const saveFile = async (fileName: string, existingFileSave: boolean, temporaryLocalChangesSave: boolean, updatedFile?: any, saveTag?: string): Promise<string | undefined> => {
                console.log('starting the saving process');
                showProgressSpinner();
                const fileId = fileNameAndIdMap.get(fileName);
                savingInProgress = true;
                //turn off the inactivity timer
                window.dispatchEvent(new Event('processStarted'))

                const doc = documentViewer.getDocument();
                await doc.getDocumentCompletePromise();
                const xfdfString = await annotationManager.exportAnnotations({fields: true});
                console.log('got XPDF string');

                let data;
                try {
                    data = await doc.getFileData({ includeAnnotations: true, xfdfString: xfdfString, flags: PDFNet.SDFDoc.SaveOptions.e_linearized});
                } catch (e) {
                    console.log('error getting file data. The server may have run out of memory.')
                    console.log(e);
                    dispatch(showSnackbar({message: "Error getting file data", type: "error"}));
                    stopSaving();
                    return;
                }
                console.log('got file data')
                //This string contains all the information about annotations in the document.
                const arr = new Uint8Array(data);
                const blob = new Blob([arr]);

                const file = new File([blob], fileName, {type: 'application/pdf'})
                //We can be sure project ID is not null because we disable the save buttons when accessing the webviewer not from a project

                console.log('uploading file to s3');
                if (temporaryLocalChangesSave) {
                    return updateFileUsingS3(updatedFile ? updatedFile : file, p.projectID!, fileId as string, true, saveTag).then(saveLocation => {
                        savingInProgress = false;
                        return 'success'
                    }).catch(reason => {
                        stopSaving();
                        return undefined;
                    })
                }
                return updateFileUsingS3(updatedFile ? updatedFile : file, p.projectID!, fileId as string, false, saveTag).then(saveLocation => {
                    console.log('got a return value from s3');
                    const currentDocumentLogs = logs.filter(log => log.document === fileName)
                    // @ts-ignore
                    uploadLogsToS3(currentDocumentLogs, filterPDFExtension(fileName), p.projectID.toString())
                    logs = []
//                  logs = logs.filter(log => log.document !== fileName)
                    if(p.isRestoring) {
                        dispatch(showSnackbar({message: `Successfully restored ${fileName}`, type: "info"}));
                    }
                    else {
                        dispatch(showSnackbar({message: `Successfully saved ${fileName}`, type: "info"}));
                    }

                    if(fileName === instance.UI.TabManager.getActiveTab().options.filename) {
                        localStorage.removeItem('blobData')
                    }
                    documentEdited = false;
                    setContinueTracking(prev => !prev)
                    if(editedDocs.length > 1){
                        setIsEdit(true)
                    }else{
                        setIsEdit(false)
                    }
                    if(closeBtn && currentDoc) {
                        removeDocsFromEditArgs(Number(currentDoc))
                        closeBtn?.click()
                        closeBtn = undefined;
                        currentDoc = undefined
                    }
                    //decideNavigationChangeOrDeleteTab()
                    window.removeEventListener('beforeunload', handleBeforeUnload);
                    stopSaving();
                    hideModal()
                    return undefined;
                }).catch(reason => {
                    console.log("failed to save file", reason)
                    dispatch(showSnackbar({message: "Error Saving File!", type: "error"}));
                    stopSaving();
                    return undefined;
                })
            }

            //Disable the standard save as button and add our own
            instance.UI.disableElements(["saveAsButton"]);
            if (p.projectID) {
                // Add an item in the menu for saving
                instance.UI.settingsMenuOverlay.add([{
                    type: 'actionButton',
                    className: "row data-save-button",
                    img: 'icon-save',
                    onClick: () => {
                        instance.UI.closeElements(['menuOverlay']);
                        saveFileInPlace(true);
                    },
                    label: 'Save'
                }], "downloadButton"); //Put this after the download button
            }

            //Menu item for OCR
            instance.UI.settingsMenuOverlay.add([{
                type: 'actionButton',
                className: "row ocr-button",
                img: 'icon-header-search',
                onClick: () => {
                    instance.UI.closeElements(['menuOverlay']);
                    ocrDocument();
                },
                label: 'OCR Scan'
            }], "downloadButton");

            //Menu item for sanitizing
            instance.UI.settingsMenuOverlay.add([{
                type: 'actionButton',
                className: "row sanitize-button",
                img: 'ic_annotation_eraser_black_24px',
                onClick: () => {
                    setSanitizationPayloadHelper(prev=>({...prev, isNew: true}))
                    instance.UI.closeElements(['menuOverlay']);
                    sanitize();
                },
                label: 'Sanitize'
            }], "downloadButton");


            // @ts-ignore
            ContentEdit.addEventListener('textContentUpdated', () => {
                //I'm not actually sure when this is called. It's not called when the user replaces text, but that gets
                //captured by the annotation changing.
                console.log('content edited')
                documentEdited = true
                setIsEdit(true)
                trackEditedFiles(instance.UI.TabManager.getActiveTab().id, iframeDoc,'add')
            });


            const autoSave = () => {
                // This variable is set to true on the events annotationChanged and textContentUpdated. Also after sanitizing,
                // OCR, changing category, and changing style. As far as I can tell that covers all the times the user
                // changes the document. But we should check this again when we add more features.
                if (documentEdited && !savingInProgress) {
                    documentEdited = false
                    setIsEdit(false)
                    //trackEditedFiles(instance.UI.TabManager.getActiveTab().id, iframeDoc)
                    console.log('autosaved document')
                } else {
                    console.log('not saving because the document has not changed')
                }
            }

            // Autosave every 180 seconds.
            // autosaveIntervalRef.current = setInterval(autoSave, 180000);
            //#endregion

            const searchAcrossDocsButton = {
                type: 'statefulButton',
                initialState: 'OneDoc',
                states: {
                    OneDoc: {
                        searchAcrossDocs: false,
                        img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" fill="#000000"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><path d="M7,9H2V7h5V9z M7,12H2v2h5V12z M20.59,19l-3.83-3.83C15.96,15.69,15.02,16,14,16c-2.76,0-5-2.24-5-5s2.24-5,5-5s5,2.24,5,5 c0,1.02-0.31,1.96-0.83,2.75L22,17.59L20.59,19z M17,11c0-1.65-1.35-3-3-3s-3,1.35-3,3s1.35,3,3,3S17,12.65,17,11z M2,19h10v-2H2 V19z"/></g></svg>',
                        onClick: (update: (newState: any) => void) => {
                            setSearchAcrossDocs(true)
                            update('AllDocs');
                        },
                        title: 'Searching only the current document'
                    },
                    AllDocs: {
                        searchAcrossDocs: true,
                        img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px"><g><rect x="0" y="0" width="24" height="24" rx="5" fill="#111122"/></g><g><path d="M7,9H2V7h5V9z M7,12H2v2h5V12z M20.59,19l-3.83-3.83C15.96,15.69,15.02,16,14,16c-2.76,0-5-2.24-5-5s2.24-5,5-5s5,2.24,5,5 c0,1.02-0.31,1.96-0.83,2.75L22,17.59L20.59,19z M17,11c0-1.65-1.35-3-3-3s-3,1.35-3,3s1.35,3,3,3S17,12.65,17,11z M2,19h10v-2H2 V19z"/></g></svg>',
                        onClick: (update: (newState: any) => void) => {
                            setSearchAcrossDocs(false)
                            update('OneDoc');
                        },
                        title: 'Searching all open documents'
                    }
                },
                dataElement: 'searchAcrossDocsButton'
            };

            function getActiveTabIndex() {
                let activeTabId = instance.UI.TabManager.getActiveTab().id
                let activeTabIndex = 0
                instance.UI.TabManager.getAllTabs().forEach((tab, index) => {
                    // @ts-ignore
                    if (tab.id===activeTabId) {
                        activeTabIndex = index
                    }
                })
                return activeTabIndex
            }

            function endComparisonReport() {
                setcreatingReportInProgress(false);
                loadDocumentForComparisonReport = false
                goldStandardFileAnnotations = []
            }

            const createReport = (event: any) => {
                const sortedAnnotations = annotationManager.getAnnotationsList().sort((a, b) => sortByOrderOfAppearnceInDocument(a, b))
                    .filter(annotation => annotation.elementName !== 'link')

                if (event.target.value === "patientReport") {
                    const { headerRow, reportRowsArray } = generatePatientReport(sortedAnnotations, getDecodedFileName())
                    localStorage.setItem("patientReportHeaderRow", JSON.stringify(headerRow));
                    localStorage.setItem("patientReportRows", JSON.stringify(reportRowsArray));
                    window.open(`/app/user/docs/${p.projectID}/patientReport`);
                } else if (event.target.value === "batchReport") {
                    const { headerRow, reportRowsArray } = generateReport(sortedAnnotations, getDecodedFileName())
                    localStorage.setItem("batchReportHeaderRow", JSON.stringify(headerRow));
                    localStorage.setItem("batchReportRows", JSON.stringify(reportRowsArray));
                    window.open(`/app/user/docs/${p.projectID}/batchReport`);
                } else if (event.target.value === "marksReport") {
                    const { headerRow, reportRowsArray } = generateMarksReport(sortedAnnotations, getDecodedFileName())
                    localStorage.setItem("marksReportHeaderRow", JSON.stringify(headerRow));
                    localStorage.setItem("marksReportRows", JSON.stringify(reportRowsArray));
                    window.open(`/app/user/docs/${p.projectID}/marksReport`);
                } else if (event.target.value === "transformsReport") {
                    const { headerRow, reportRowsArray } = generateTransformsReport(sortedAnnotations, getDecodedFileName())
                    localStorage.setItem("transformsReportHeaderRow", JSON.stringify(headerRow));
                    localStorage.setItem("transformsReportRows", JSON.stringify(reportRowsArray));
                    window.open(`/app/user/docs/${p.projectID}/transformsReport`);
                } else if (event.target.value === "searchReport") {
                    const { headerRow, reportRowsArray } = generateSearchResultsReport(documentViewer.getPageSearchResults(), getDecodedFileName())
                    localStorage.setItem("searchReportHeaderRow", JSON.stringify(headerRow));
                    localStorage.setItem("searchReportRows", JSON.stringify(reportRowsArray));
                    window.open(`/app/user/docs/${p.projectID}/searchReport`);
                } else if (event.target.value === "compareFilesReport") {
                    if (instance.UI.TabManager.getAllTabs().length>1) {
                        setcreatingReportInProgress(true);
                        let goldStandardTabIndex = 0
                        let activeTabIndex = getActiveTabIndex()

                        // @ts-ignore
                        instance.UI.TabManager.getAllTabs().forEach((tab, index) => indexesOfFilesToCompare.push(index))
                        indexesOfFilesToCompare.shift()//remove the gold standard from list
                        loadDocumentForComparisonReport = true
                        if (activeTabIndex === goldStandardTabIndex) {
                            handleOpeningGoldStandard()
                        } else {
                            //open gold standard if it is not already open
                            openingGoldStandard = true
                            // @ts-ignore
                            let goldStandardTabId = instance.UI.TabManager.getAllTabs()[goldStandardTabIndex].id
                            instance.UI.TabManager.setActiveTab(goldStandardTabId)
                        }
                    } else {
                        dispatch(showSnackbar({ message: `Files Comparison require two or more tabs open`, type: "error" }));
                    }
                } else if (event.target.value === "logs") {
                    window.open('/app/user/docs/' + getEncodedFileName(filterPDFExtension(getDecodedFileName())) + '/' + p.projectID + '/logsFile');
                }

            }

            const ReportsMenu = () => {
                return (
                    <select
                        placeholder={"Reports"} title={"Reports"}
                        onChange={createReport}
                        value={""}
                        defaultValue={""}
                    >
                        <option key={0} value="" disabled hidden={true}>Reports</option>
                        <option key={1} value="batchReport">Batch Report</option>
                        <option key={2} value="patientReport">Patient Report</option>
                        <option key={3} value="marksReport">Marks Report</option>
                        <option key={4} value="transformsReport">Transforms Report</option>
                        <option key={5} value="searchReport">Search Report</option>
                        <option key={6} value="compareFilesReport">Compare Files</option>
                        <option key={7} value="logs">Logs</option>
                    </select>
                );
            }

            const reportsElement = {
                type: 'customElement',
                render: () => <ReportsMenu />
            };

            let manualCategory: string | undefined = undefined
            const onManualMarkCategoryChange = (category: string) => {
                manualCategory = category
                changeMarkStyleSettings(category);
            }

            //This needs to be in a function so that the value for selectedItem gets updated
            const getManualMarkElement = () => {
                return {
                    type: 'customElement',
                    title: "Category for manual marks",
                    dataElement: "manualMarkElement",
                    render: () => <ManualMarkMenu categories={categories} onChange={onManualMarkCategoryChange} selectedItem={manualCategory} patternMap={patternMap}/>
                };
            }
            const changeOverlayFontButton = () => {
                return {
                    type: 'actionButton',
                    img: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">\n' +
                        '  <path d="m0 0h24v24h-24z" fill="none"/>\n' +
                        '  <path d="m12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5h1.77c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm3-4c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm5 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm3 4c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"/>\n' +
                        '</svg>',
                    onClick: async () => {
                        annotationManager.trigger('changeFont')
                    },
                    title: 'Change Overlay Font',
                    dataElement: 'changeOverlayFontButton'
                }
            }
            if(iframeDoc.querySelector<HTMLButtonElement>('[data-element="changeOverlayFontButton"]') !== null){
                iframeDoc.querySelector<HTMLButtonElement>('[data-element="changeOverlayFontButton"]')!.disabled =  true;
            }

            const bulkAdjustMarksButton = () => {
                return {
                    type: 'actionButton',
                    img: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">\n' +
                        '  <path d="m0 0h24v24h-24z" fill="none"/>\n' +
                        '  <path d="m18 18v-3h2v3h3v2h-3v3h-2v-3h-3v-2zm-14 2v-2h2v2zm0-3.5v-2h2v2zm0-3.5v-2h2v2zm0-3.5v-2h2v2zm0-3.5v-2h2v2zm3.5 0v-2h2v2zm3.5 0v-2h2v2zm0 14v-2h2v2zm3.5-14v-2h2v2zm3.5 0v-2h2v2zm0 7v-2h2v2zm0-3.5v-2h2v2zm-10.5 10.5v-2h2v2z"/>\n' +
                    '</svg>',
                    onClick: async () => {
                        annotationManager.trigger('adjustMarks')
                    },
                    title: 'Bulk Adjust Marks',
                    dataElement: 'bulkAdjustMarksButton'
                }
            }
            if(iframeDoc.querySelector<HTMLButtonElement>('[data-element="bulkAdjustMarksButton"]') !== null){
                iframeDoc.querySelector<HTMLButtonElement>('[data-element="bulkAdjustMarksButton"]')!.disabled =  true;
            }
            const onChangeMarkStyle = (newStyle: number) => {
                markStyle = markStyles.find((style) => style.id === +newStyle);
                setSelectedMarkStyle(markStyle)
                changeMarkStyleSettings(manualCategory)
            }

            const changeMarkStyleSettings = (category: string|undefined) => {
                documentViewer.getTool('AnnotationCreateRedaction').setStyles(getMarkStyleSettings(category));
            }

            const convertRgbaToColor = (item: { R: number; B: number; G: number; A: number }) => {
                return new Annotations.Color(item.R, item.G, item.B, item.A);
            }

            const getMarkStyleSettings = (category: string|undefined, includeFontSize=true) => {
                const [styleCategory, displayCategory] = getStyleAndDisplayCategory(category)
                let settings;
                if (styleCategory === 'cci') {
                    settings = markStyle.props.cci;
                } else if (styleCategory === 'cbi') {
                    settings = markStyle.props.cbi;
                } else if (displayCategory === 'fullPage') {
                    settings = fullPageRedactionStyle
                } else {
                    settings = markStyle.props.normal;
                }

                let markStyleSettings = {
                    OverlayText: settings.OverlayText,
                    TextAlign: settings.TextAlign,
                    TextColor: convertRgbaToColor(settings.TextColor),
                    StrokeColor: convertRgbaToColor(settings.StrokeColor),
                    FillColor: convertRgbaToColor(settings.FillColor)
                }
                if (includeFontSize) {
                    Object.assign(markStyleSettings, {FontSize: settings.FontSize})
                }
                console.log('markStyleSettings', markStyleSettings)
                return markStyleSettings
            }

            let markStyle: any;
            if (activeProject) {
                markStyle = markStyles.find((style) => style.name === activeProject.markStyleName) ?? markStyles[0];
            } else {
                markStyle = markStyles[0]
            }
            setSelectedMarkStyle(markStyle)
            changeMarkStyleSettings(manualCategory)

            const getMarkStyleDropdown = () => {
                return {
                    type: 'customElement',
                    title: 'Mark Style',
                    dataElement: 'markStyleDropdown',
                    render: () => <MarkStyleDropdown selectedStyle={+markStyle.id} onChange={onChangeMarkStyle}/>
                }
            }

            //#region text select search

            const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms))

            // @ts-ignore
            const patterns: Pattern[] | undefined = p.patternSetID ? await getApiPatternsBySetId(p.patternSetID) : await getApiDefaultPatterns()
            const patternMap = new Map<string, Pattern>();
            patterns?.forEach((pattern) => {
                patternMap.set(pattern.name, pattern);
            });
            let redactSearchPatterns: { label: string, type: string, regex: RegExp }[] = patterns ? await getPatternsForDocViewer(patterns) : DefaultRedactSearchPatterns
            redactSearchPatterns.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1);
            let categories: {label: string, type: string}[] = []
            redactSearchPatterns.map(pattern => categories.push({label: pattern.label, type: pattern.type}))
            redactSearchPatternsLoaded = true
            setSearchBarOptions(redactSearchPatterns.filter(pattern => pattern.regex !== undefined))
            //We enable the UI after redactSearchPatterns are loaded and the initial page rendering is finished
            if (initialPagesRendered) {
                handleFinishedInitialLoading()
            }

            let smartFiltersToggle = false

            const smartFilterManager: SmartFilterManager = new SmartFilterManager(redactSearchPatterns)

            const smartFilterButton = {
                type: 'statefulButton',
                initialState: 'NoSmartFilters',
                states: {
                    NoSmartFilters: {
                        img: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"/></svg>',
                        onClick: (update: (newState: any) => void) => {
                            smartFiltersToggle = true
                            console.log(`smart filters on? ${smartFiltersToggle}`)
                            update('SmartFilters');
                        },
                        title: "Smart Filters Off"
                    },
                    SmartFilters: {
                        img: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect x="0" y="0" width="24" height="24" rx="5" fill="#111122"/></g><path d="M0 0h24v24H0z" fill="none"/><path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"/></svg>',
                        onClick: (update: (newState: any) => void) => {
                            smartFiltersToggle = false
                            console.log(`smart filters on? ${smartFiltersToggle}`)
                            update('NoSmartFilters');
                        },
                        title: "Smart Filters On (remove likely false positives)"
                    }
                },
                dataElement: 'deduplicateButton'
            };

            documentViewer.addEventListener('notify', (notification: string) => { console.log(notification) })

            instance.UI.setHeaderItems(header => {
                header.delete('toggleNotesButton')
                header.delete('searchButton')
                header.push(commentButton)
                header.push(reportsElement)
                header.push(searchAcrossDocsButton)
                header.push(deduplicateButton)
                header.push(smartFilterButton)
                header.getHeader('toolbarGroup-Multi-Panel').get('eraserToolButton').insertAfter(bulkAdjustMarksButton())
                header.getHeader('toolbarGroup-Multi-Panel').get('undoButton').insertBefore(getMarkStyleDropdown())
                header.getHeader('toolbarGroup-Multi-Panel').get('bulkAdjustMarksButton').insertAfter(changeOverlayFontButton())
                header.getHeader('toolbarGroup-Multi-Panel').get('changeOverlayFontButton').insertAfter(getManualMarkElement())
            });


            //This lets the user double-click a word, click the search icon, and a search will be performed for all instances of that word.
            //The results get added as marks.
            const searchOptions = {
                type: 'actionButton',
                img: 'icon-header-search',
                onClick: async () => {
                    const results: any[] = [];
                    const mode = instance.Core.Search.Mode.REGEX | instance.Core.Search.Mode.WILD_CARD | instance.Core.Search.Mode.HIGHLIGHT;
                    const searchOptions = {
                        // If true, a search of the entire document will be performed. Otherwise, a single search will be performed.
                        fullSearch: true,
                        // The callback function that is called when the search returns a result.
                        onResult: (result: { resultCode: number; quads: { getPoints: () => any; }[]; pageNum: any; }) => {
                            if (result.resultCode === instance.Core.Search.ResultCode.FOUND) {
                                const textQuad = result.quads[0].getPoints();
                                if (textQuad !== undefined && result.pageNum !== undefined) {
                                    const annot = new Annotations.RedactionAnnotation({
                                        PageNumber: result.pageNum,
                                        Quads: [textQuad],
                                        StrokeColor: new Annotations.Color(255, 0, 0, 1),
                                    });
                                    annot.setContents(documentViewer.getSelectedText());
                                    //In Adobe and Docs Desktop, the Author field is displayed as the type. So we're staying consistent with that and
                                    //we added a custom field that actually contains the author.
                                    annot.setCustomData('author', loginInfo?.tenant?.user?.name || 'Unknown')
                                    annot.Author = "quick-search";
                                    //Not sure why there's a compile error here. It works fine when we ignore it.
                                    // @ts-ignore
                                    annot.type = "quick-search"
                                    results.push(annot);
                                    annotationManager.addAnnotation(annot);

                                }
                            }
                        }
                    };

                    documentViewer.textSearchInit(documentViewer.getSelectedText(), mode, searchOptions);
                    await annotationManager.drawAnnotationsFromList(results);
                },
                dataElement: 'highlightedTextSearch'
            }
            instance.UI.textPopup.add([searchOptions], 'textPopup');
            //#endregion

            //#region Annotation events
            function changeAnnotationColor(annotation: any, category: string) {
                if (patternMap.has(category)) {
                    const [red, green, blue] = convertHexStringToRgbArray(patternMap.get(category)!.color)
                    annotation.Color = new Annotations.Color(red, green, blue, 1.0)
                }
            }

            const changeHighlightCategory = (annotation: any, category: string, updateManualCategory: boolean) => {
                changeAnnotationCategory(annotation, category, updateManualCategory, false)
                dispatch(showSnackbar({ message: `Category successfully changed!`, type: "info" }));
            }

            const updateAnnotationCategory = (annotation: any, category: string, updateManualCategory: boolean) => {
                changeAnnotationCategory(annotation, category, updateManualCategory, true)
                dispatch(showSnackbar({ message: `Category successfully changed!`, type: "info" }));
            }
            const changeAnnotationCategory = (annotation: any, category: string, updateManualCategory: boolean, updateAnnotationColor=true) => {
                const displayCategory = getDisplayCategory(category)
                annotation.type = displayCategory
                annotation.setCustomData('trn-redaction-type', displayCategory)
                annotation.Author = displayCategory;
                annotation.setCustomData('author', loginInfo?.tenant?.user?.name || 'Unknown');
                if (updateAnnotationColor) {//we don't update for highlights
                    annotationManager.setAnnotationStyles(annotation, getMarkStyleSettings(category, false))// changing category should not impact the overlay font size
                    changeAnnotationColor(annotation, displayCategory)
                }
                //redraw with the new category and color.
                annotationManager.redrawAnnotation(annotation)
                annotationManager.trigger('categoryChanged');
                documentEdited = true;
                setIsEdit(true)
                trackEditedFiles(instance.UI.TabManager.getActiveTab().id, iframeDoc,'add')
                if (updateManualCategory && manualCategory !== category) {
                    manualCategory = category;
                    changeMarkStyleSettings(category)
                    //Update the category picker by deleting it and adding it again
                    instance.UI.setHeaderItems(header => {
                        header.getHeader('toolbarGroup-Multi-Panel').delete('manualMarkElement')
                        header.getHeader('toolbarGroup-Multi-Panel').get('eraserToolButton').insertAfter(getManualMarkElement())
                    });
                }
            }

            annotationManager.addEventListener('changestatus', () => {
                if (p.taskId) {
                    showModal(ChangeStatusModal, {
                        initialTaskStatus: taskStatus.current as string,
                        onSelection: async (checked) => {
                            // update the status
                            try {
                                taskStatus.current = checked
                                dispatch(showProgressLine());
                                await putApiTasksStatusById(p.taskId as number, { status: checked as any })
                                dispatch(hideProgressLine());
                                dispatch(showSnackbar({ message: `Status successfully changed!`, type: "info" }));
                            }
                            catch (err) {
                                dispatch(hideProgressLine());
                                dispatch(showSnackbar({ message: `Error updating status`, type: "error" }));
                            }
                        }
                    })
                } else {
                    dispatch(showSnackbar({message: `No task id linked with the opened document`, type: "error"}));
                }
            })

            //For changing the category of existing marks using the Change Category button.
            annotationManager.addEventListener('changeCategory', ({annotations}) => {
                if (annotations.length > 0) {
                    openMatchCategoryModal(categories, instance, annotations, updateAnnotationCategory, manualCategory, false, setShowingProgressSpinner)
                }
            })

            annotationManager.addEventListener('changeOutlineColor', async ({annotations}) => {
                if (annotations.length > 0) {
                    showModal(OutlineColorPickerModal, {annotations: annotations})
                }
            })

            //For changing the category of existing marks using the Change Category button.
            annotationManager.addEventListener('replacedChangeCategory', () => {
                //@ts-ignore
                let highlights = annotationManager.getAnnotationsList().filter(redaction => redaction.highlightChecked)
                openMatchCategoryModal(categories, instance, highlights, changeHighlightCategory, manualCategory, false, setShowingProgressSpinner)
            })

            annotationManager.addEventListener('styleUpdate', ({ style, redactions }) => {
                dispatch(showSnackbar({ message: `Mark style changed to ${style}`, type: "info" }))
                redactions.map((redaction: any) => adjustFontSizeToFit(redaction))
                documentEdited = true;
                setIsEdit(true)
                trackEditedFiles(instance.UI.TabManager.getActiveTab().id, iframeDoc,'add')
            })

            annotationManager.addEventListener('transformText', async () => {
                //@ts-ignore
                replaceAnnotationsInPlace(annotationManager.getAnnotationsList().filter(redaction => redaction.transformChecked), 'transform')
            })
            annotationManager.addEventListener('retransformText', () => {
                console.log('retransforming text')
                //@ts-ignore
                replaceAnnotationsInPlace(annotationManager.getAnnotationsList().filter(redaction => redaction.highlightChecked), 'replacement')
            })
            annotationManager.addEventListener('revertText', () => {
                console.log('reverting text')
                //@ts-ignore
                revertHighlightsInPlace(annotationManager.getAnnotationsList().filter(redaction => redaction.highlightChecked))
            })
            annotationManager.addEventListener('changeFont', () => {
                console.log('changing font')
                instance.UI.openElements([bulkChangeOverlayFontModal.dataElement])
            })
            annotationManager.addEventListener('fontSizeChanged', ({ fontSize }) => {
                fontSizeForSelectedMarks = fontSize
                instance.UI.openElements([updateFontSizeOfSelectedMarks.dataElement])
            })
            annotationManager.addEventListener('changeHighlightContentFont', () => {
                console.log('changing Highlight content Font')
                instance.UI.openElements(['selectFontTypeAndSizeModal'])
            })
            annotationManager.addEventListener('editMark', () => {
                instance.UI.hotkeys.off()//it is important to turn off the hotkeys, because it results in a strange behavior when the user starts typing in the modal
                instance.UI.openElements(['editMarkModal'])
            })


            annotationManager.addEventListener('adjustMarks', bulkAdjustMarks);

            annotationManager.addEventListener('annotationChanged', (annotations: any[], action, { imported }) => {
                // If the event is triggered by importing then it can be ignored
                // This will happen when importing the initial annotations
                // from the server or individual changes from other users
                if (imported || action==='replacementChange') return;

                documentEdited = true;
                setIsEdit(true)
                trackEditedFiles(instance.UI.TabManager.getActiveTab().id, iframeDoc,'add')

                let pages: number[] = annotations.map(annotation => annotation.getPageNumber())
                // Three different events available for autosaving/other after redact callbacks
                findButton()
                switch (action) {
                    case 'delete': {
                        if (clickedApplyFlag) {
                            logs.push({ date: getCurrentDate(), time: getCurrentTime(), document: getDecodedFileName(), user: loginInfo?.tenant?.user?.name, roles: loginInfo?.tenant?.user?.roles, action: logAction.APPLY, annotationType: annotations[0].Subject, pages: pages })
                        } else {
                            logs.push({ date: getCurrentDate(), time: getCurrentTime(), document: getDecodedFileName(), user: loginInfo?.tenant?.user?.name, roles: loginInfo?.tenant?.user?.roles, action: logAction.DELETE, annotationType: annotations[0].Subject, pages: pages })
                        }
                        clickedApplyFlag = false

                        break;
                    }
                    case 'add': {
                        let redactions = annotations.filter(redaction => redaction instanceof Annotations.RedactionAnnotation)
                        redactions.map(redaction => adjustFontSizeToFit(redaction))
                        //When a user adds a redaction manually, ask them what the category is.
                        if (annotations.length === 1 && (!annotations[0].type || annotations[0].type === loginInfo?.tenant?.user?.name) && annotations[0].Subject === 'Redact') {
                            //This needs to be stored in contents for it to show up in Adobe.
                            annotations[0].setContents(annotations[0].getCustomData('trn-annot-preview'));
                            if (manualCategory) {
                                changeAnnotationCategory(annotations[0], manualCategory, false);
                            } else if (!doNotShowSelectCategoryFlag) {
                                openMatchCategoryModal(categories, instance, [annotations[0]], changeAnnotationCategory, manualCategory, true, setShowingProgressSpinner)
                            }
                        }
                        //Set the color based on the category
                        annotations.filter(annotation => annotation.Subject === 'Redact').forEach(annotation => {

                            changeAnnotationColor(annotation, annotation.type)
                            if (annotation.type === 'fullPage') {
                                annotationManager.setAnnotationStyles(annotation, getMarkStyleSettings(annotation.type))
                                adjustFontSizeToFit(annotation)
                            }
                        })
                        zoomLevel = instance.UI.getZoomLevel()
                        instance.UI.setZoomLevel(zoomLevel)//This is a workaround to fix an issue in the out of scope pages redaction. we noticed
                        //that the overlay text sometimes doesn't appear, but if you adjust the zoom it appears. This here adjusts the zoom to its current level
                        logs.push({ date: getCurrentDate(), time: getCurrentTime(), document: getDecodedFileName(), user: loginInfo?.tenant?.user?.name, roles: loginInfo?.tenant?.user?.roles, action: logAction.ADD, annotationType: annotations[0].Subject, pages: pages })
                        break;
                    }
                    case 'modify': {
                        //TODO: when we enable the edit text feature. I think this is the event that gets called when you edit. We need to then log that the document was edited
                        logs.push({ date: getCurrentDate(), time: getCurrentTime(), document: getDecodedFileName(), user: loginInfo?.tenant?.user?.name, roles: loginInfo?.tenant?.user?.roles, action: logAction.MODIFY, annotationType: annotations[0].Subject, pages: pages })
                        if(bulkAdjustMarksArray.length > 1) {
                            bulkAdjustMarksResize(annotations)
                        }
                        break;
                    }
                }
            });
            findButton()
            function findButton(){
                interval = setInterval(()=>{
                    const annotationPopup = iframeDoc.querySelector<HTMLElement>('[data-element="annotationCommentButton"]');
                    if(annotationPopup !==undefined && annotationPopup !== null){
                        setAddCommentButton(annotationPopup)
                        clearInterval(interval);
                        return
                    }
                }, 1000);
            }

            const bulkAdjustMarksResize = async( annotations : any[]) => {
                const redrawnAnnot = annotations[0];
                let x1Diff = 0
                let x2Diff = 0;
                let y1Diff = 0;
                let y2Diff = 0;
                for(let i = 0; i < bulkAdjustMarksArray.length; i++) {
                    // @ts-ignore
                    if(redrawnAnnot.Id === bulkAdjustMarksArray[i].Id) {
                        const redrawnAnnotRect = redrawnAnnot.getRect();
                        // @ts-ignore
                        x1Diff = redrawnAnnotRect.x1 - rectArray[i].x1;
                        x2Diff = redrawnAnnotRect.x2 - rectArray[i].x2;
                        y1Diff = redrawnAnnotRect.y1 - rectArray[i].y1;
                        y2Diff = redrawnAnnotRect.y2 - rectArray[i].y2;

                        break;
                    }
                }

                annotationManager.getAnnotationsList().forEach(redaction => {
                    //@ts-ignore
                    if (redaction.markChecked && redaction.Id !== redrawnAnnot.Id) {
                        const rect = redaction.getRect();
                        // @ts-ignore
                        const newX1 = rect.x1 + (x1Diff);
                        // @ts-ignore
                        const newX2 = rect.x2 + (x2Diff);
                        // @ts-ignore
                        const newY1 = rect.y1 + (y1Diff);
                        // @ts-ignore
                        const newY2 = rect.y2 + (y2Diff);

                        let redactionRect = redaction.getRect();
                        //Make sure we don't go off the page, I can't find the values for the max of the page
                        redactionRect.x1 = Math.max(newX1, 0);
                        redactionRect.x2 = Math.max(newX2, 0);
                        redactionRect.y1 = Math.max(newY1, 0);
                        redactionRect.y2 = Math.max(newY2, 0);
                        redaction.resize(redactionRect);

                    }}
                    )
                rectArray=[];
                bulkAdjustMarksArray=[]
                documentEdited=true;
                setIsEdit(true)
                trackEditedFiles(instance.UI.TabManager.getActiveTab().id, iframeDoc,'add')
                isBulkMarkAdjustSelected=false;
                console.log("bulk adjusted marks");
                dispatch(showSnackbar({
                    message: "Bulk Adjusted Marks",
                    type: "info"
                }));
                // clear the cache (rendered) data with the newly updated document
                documentViewer.refreshAll();
                // Update viewer to render with the new document
                documentViewer.updateView();
                // Refresh searchable and selectable text data with the new document
                documentViewer.getDocument().refreshTextData();
            }

            async function marginFilter(result: SearchResult) {
                const {width, height} = await documentViewer.getDocument().getPageInfo(result.pageNum)
                let rotation = documentViewer.getDocument().getPageRotation(result.pageNum)
                const searchRegionRectangle = await getPageSearchRegionRectangle(width, height, rotation)
                let shouldFilter = result.quads.every(quad => {
                    const resultRectangle = quadToRectangle(quad);
                    if (!contains(searchRegionRectangle, resultRectangle)) {
                        return true
                    }
                    return false
                })
                return shouldFilter
            }
            //Return true if a search result should not be displayed, either because it's overlapping an annotation
            //that's already there or because smart filters want to remove it.
            const shouldFilter = async (result: SearchResult) => {
                if (!result || result.resultCode !== instance.Core.Search.ResultCode.FOUND) {
                    return false
                }

                let shouldFilter = false;
                if (smartFiltersToggle) {
                    const pageText = await getPageText(result.pageNum)
                    if (smartFilterManager.shouldFilter(result, pageText)) {
                        console.log('Smart Filtered')
                        shouldFilter = true
                    }
                }
                if (deduplicateSearchResultsToggle && !shouldFilter) {
                    //Check if each quad of the search result is covered by the quad of an annotation already on the page.
                    const annotationsOnPage = annotationManager.getAnnotationsList().filter(annotation => annotation.getPageNumber() === result.pageNum)
                    const isCovered = result.quads.every((quad: any) => isQuadFullyCovered(quad, annotationsOnPage))
                    if (isCovered) {
                        console.log('Deduplicated')
                        shouldFilter = true
                    }
                }
                if (!shouldFilter) {
                    shouldFilter = await marginFilter(result)
                }
                return shouldFilter
            }
            //I was having issues when I passed the function directly, but it works if it's a field.
            setFilterFunction({'function': shouldFilter})

            async function getSortedProjectFiles(): Promise<FileState[]> {
                function compareDates(a: FileState, b: FileState): number {
                    const dateA = new Date(a.lastModifiedAt);
                    const dateB = new Date(b.lastModifiedAt);

                    return dateB.getTime() - dateA.getTime();
                }

                const files = await getApiFilesByProjectId(p.projectID!);
                files.sort(compareDates)
                return files;
            }

            const loadTransformCallback = () => {
                const showRiskModal = () => {
                    showProgressSpinner();
                    getSortedProjectFiles().then(files => {
                        hideProgressSpinner();
                        showModal(LoadTransformsFromRiskModal, {projectFiles: files, annotations: getAnnotationsWithoutManualEdits(), onDone: afterLoadingTransforms})
                    });
                }
                warnAndContinue(showRiskModal)
            }

            const anyReplacementText = () => {
                return annotationManager.getAnnotationsList().find(annotation => annotation.getCustomData(REPLACEMENT_TEXT_COLUMN) && !annotation.getCustomData("ManuallyEdited") && !(annotation instanceof instance.Core.Annotations.TextHighlightAnnotation));
            }

            const categoricalTransform = () => {
                const showCategoricalTransformModal = () => {
                    //Apply to just the checked annotations. But if none are checked then apply to all of them
                    const annotations = getAnnotationsWithoutManualEdits();
                    // @ts-ignore
                    const checkedAnnotations = annotations.filter(redaction => redaction.transformChecked)
                    showModal(LoadFromCategoryModal, {annotations: checkedAnnotations.length > 0 ? checkedAnnotations : annotations, onDone: afterLoadingTransforms})
                }
                warnAndContinue(showCategoricalTransformModal);
            }

            const transformsFromReport = () => {
                console.log('loading transforms from report')
                const showReportModal = () => {
                    showProgressSpinner();
                    getSortedProjectFiles().then(files => {
                        hideProgressSpinner();
                        showModal(TransformFromReportModal, {projectFiles: files, annotations: getAnnotationsWithoutManualEdits(), onDone: afterLoadingTransforms})
                    });
                }
                warnAndContinue(showReportModal);
            }

            const transformsFromLookup = async () => {
                try {
                    // Ask the user to choose an Excel file.
                    const [fileHandle] = await (window as any).showOpenFilePicker({
                        types: [{
                                description: 'Excel Files',
                                accept: {'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],},
                        },],
                        multiple: false,
                    });
                    showProgressSpinner();
                    const file = await fileHandle.getFile();
                    await loadTransformsFromLookup(file, getAnnotationsWithoutManualEdits());
                    annotationManager.trigger('transformsLoaded')
                    hideProgressSpinner();
                } catch (error) {
                    console.error('Error loading file:', error);
                    hideProgressSpinner();
                    dispatch(showSnackbar({ message: "Error loading lookup", type: "error" }));
                }
            }

            const warnAndContinue = (continueFunc: ()=>any) => {
                if (anyReplacementText()) {
                    showModal(OverwriteTransformsWarningModal,
                        {continue: continueFunc}
                    );
                } else {
                    continueFunc();
                }
            }
            const afterLoadingTransforms = () => {
                annotationManager.trigger('transformsLoaded')
            }

            setLoadTransformCallbacks({'risk': loadTransformCallback, 'category': categoricalTransform, 'report': transformsFromReport, 'lookup': transformsFromLookup})

            //#endregion
            const toggleDisable = () => {
                const addMoreDocButton = iframeDoc.querySelectorAll('[aria-label="Open file"]')[0];
                const length = instance.UI.TabManager.getAllTabs().length
                if(length === 0){
                    iframeDoc.querySelector<HTMLElement>('[data-element="multiTabsEmptyPage"]')!.style.display = 'none'
                }
                length >= 0 ? addMoreDocButton!.setAttribute('disabled', '') : addMoreDocButton.removeAttribute("disabled")
            }
            toggleDisable()
            //adding data index with the close button in the tab
            const closeButtons = instance.UI.iframeWindow.document.querySelectorAll('.TabsHeader .close-button-wrapper .Button');
            if(closeButtons.length > 0){
                closeButtons.forEach((button, index) => {
                    const doc: any = instance.UI.TabManager.getAllTabs()[index]
                    button.setAttribute('data-index', index.toString())
                    button.setAttribute('data-file',decodeURIComponent(doc.options.filename))
                })
            }

            instance.UI.addEventListener(instance.UI.Events.TAB_DELETED, e => {
                markDocAsClosed(e.detail.src, {isDocOpen: false});
                deleteTemporaryLocalChanges(e.detail.src)
                toggleDisable()
            });

            //Hide the multipanel if a modal from Apryse is open (because the multipanel appears on top of those modals
            //and we can't figure out how to change that).
            instance.UI.addEventListener(instance.UI.Events.VISIBILITY_CHANGED, e => {
                if (e.detail.element.endsWith("Modal")) {
                    if (e.detail.isVisible) {
                        document.querySelector(".multipanel")!.classList.add("close")
                    } else if (e.detail.element!=="filterModal") {//The filtermodal is the one in the comments panel. We want to keep the multipanel closed when it is removed
                        document.querySelector(".multipanel")!.classList.remove("close")
                    }
                }
            })
        });
     })

        return ()=>clearInterval(interval)
    }, [loginInfo, setInstance, loaded]);
    useEffect(() => {
        if(addCommentButton && addCommentButton !==null){
            addCommentButton.addEventListener('click', ()=>{
                openCommentPanel( )
            })
        }
    }, [addCommentButton]);
    function openCommentPanel(){
        if(instance) {
            instance.UI.closeElements(['multiPanel']);
            instance.UI.openElements(['notesPanel']);
            instance.UI.disableElements(['multiPanel']);
            const iframeDoc = instance.UI.iframeWindow.document;
            document.querySelector(".multipanel")!.classList.add("close")
            iframeDoc.querySelector('[data-element="toggleMultiPanel"]').classList.remove('active');
            iframeDoc.querySelector('[data-element="toggleComment"]').classList.add('active');

        }
    }

    return (
        <div className="body-container" style={{ flexDirection: "column", width: '100%' }}>
            <div style={{display: 'flex', margin: '0 0 0 0', gap: '2.3rem', height: '32px'}}>
                <Button style={{textDecoration: 'underline', color: "blue"}} onClick={() => nav(`/app/user/workflow/projects/${activeProject?.id}`)}>Back to {activeProject?.name}</Button>
            </div>
            <div className="DocViewer">
                <div className="webviewer" ref={viewer} style={{ height: "calc(100vh - 154px)", width: '70%', display: 'inline-block' }}></div>
                <Multipanel redactionSearchPatterns={searchBarOptions}/>
                {
                    (showingProgressSpinner || creatingReportInProgress) && <div className="progress-modal"><div className="spinner spinner-position-center"></div></div>
                }
            </div>
        </div>
    );
}

export interface SearchResult {
    resultCode: number,
    pageNum: number,
    resultStr: string,
    ambientStr: string,
    resultStrStart: number,
    resultStrEnd: number
    quads: any[]
}

//I'm writing custom code for checking if one rectangle contains another. PDFTron does have a method for this but it
//doesn't seem to work.
interface Rectangle {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
}

function contains(rect1: Rectangle, rect2: Rectangle): boolean {
    const tolerance = 1;

    return (
        rect1.x1 - tolerance <= rect2.x1 &&
        rect1.y1 - tolerance <= rect2.y1 &&
        rect1.x2 + tolerance >= rect2.x2 &&
        rect1.y2 + tolerance >= rect2.y2
    );
}

function rectContainsElement(rect1: any, rect2: any, pageHeight: number): boolean {
    const tolerance = 5;

    const containedHorizontally = rect1.x1 - tolerance <= rect2.x1 && rect1.x2 + tolerance >= rect2.x2
    //When it comes to the vertical comparison. The element BBox calculates the y values based on the distance from the bottom of the
    //while the rect calculates based on the distance from the top of the page. That is why we need the page height.
    const containedVertically = pageHeight-rect1.y1 + tolerance >= rect2.y2 && pageHeight-rect1.y2 - tolerance <= rect2.y1

    return (
        containedHorizontally && containedVertically
    );
}


type log = {
    date: string,
    time: string,
    document: string,
    user?: string,
    roles?: string[],
    action: string,
    annotationType: string,
    pages: number[]
}

export enum logAction {
    DELETE = "Deleted Annotations",
    ADD = "Added Annotations",
    MODIFY = "Modified Annotations",
    APPLY = "Applied Redactions"
}

export function convertHexStringToRgbArray(hexString: string): number[] {
    if (hexString === "") {
        return [0,0,0];
    }
    try {
        const red = parseInt(hexString.substring(1, 3), 16);
        const green = parseInt(hexString.substring(3, 5), 16);
        const blue = parseInt(hexString.substring(5, 7), 16);
        return [red, green, blue]
    } catch (reason) {
        console.log(`invalid hex string ${hexString}`);
        return [0,0,0]
    }
}

function getCurrentDate(): string {
    return ((new Date().getUTCMonth() + 1) + '/'
        + new Date().getUTCDate() + '/'
        + new Date().getUTCFullYear()
    )
}

function getCurrentTime(): string {
    return (new Date().getUTCHours() + ':'
        + new Date().getUTCMinutes() + ':'
        + new Date().getUTCSeconds()
    )
}

function uploadLogsToS3(logs: log[], name: string, projectId: string) {
    try {
        if (logs.length === 0) {
            return
        }
        const logsText: string = parseLogsArray(logs)
        const fileName = getLogS3Location(name)
        const file: File = new File([logsText], fileName, {type: "text/csv",});

        let formData = new FormData();

        formData.append('file', file);
        formData.append('name', fileName);
        formData.append('projectId', projectId);

        return createLogFileByProjectId(projectId, formData)
    } catch (e) {
        console.log("Failed to upload logs!");
    }
}

const logsHeaderRow = 'Date,Time,Document,User,Roles,Action,Annotation Type, Pages\n'

const parseLogsArray = (logs: log[]) => {
    let logsText: string = logsHeaderRow
    logs.forEach(log => {
        logsText += log.date + ',' + log.time + ',' + log.document + ',' + log.user + ',' + log.roles!.toString().replaceAll(',', ';') + ',' + log.action + ',' + log.annotationType + ',' + getPagesLogText(log.pages) + "\n"
    })
    //Delete trailing new line
    if (logsText.endsWith("\n")) {
        logsText = logsText.substring(0, logsText.length - 1)
    }
    return logsText
}

function getLogFileName(): string {
    return (new Date().getUTCMonth() + '-'
        + new Date().getUTCDay() + '-'
        + new Date().getUTCDate() + '-'
        + new Date().getUTCHours() + '-'
        + new Date().getUTCMinutes() + '-'
        + new Date().getUTCSeconds() + '-log'
    )
}

const getLogS3Location = (name: string) => {
    return name + '-logs/' + getLogFileName() + '.csv';
}

//This function is a UI improvement, if for example there was 3 changes on page 1. then the log text will show 1(3)
function getPagesLogText(pages: number[]) {
    let pagesAndAppearances: Map<number, number> = new Map();
    for (const num of pages) {
        if (pagesAndAppearances.has(num)) {
            pagesAndAppearances.set(num, pagesAndAppearances.get(num)! + 1)
        } else {
            pagesAndAppearances.set(num, 1)
        }
    }
    let logText = ''
    pagesAndAppearances.forEach((value: number, key: number) => {
        logText += key + '(' + value + '); '
    });
    //delete trailing '; ' symbol
    logText = logText.substring(0, logText.length - 2)
    return logText
}

const fileNamePattern = /^(.*?)(?:\s?\((\d+)\))?(\.[^.]+)$/;

//Return the file name with (1) at the end. If it already has (1), use (2), etc.
function incrementFileName(filename: string): string {
    const match = filename.match(fileNamePattern);

    if (match) {
        const baseName = match[1];
        const count = match[2] ? parseInt(match[2]) + 1 : 1;
        const extension = match[3];
        return `${baseName} (${count})${extension}`;
    }

    return `${filename} (1)`;
}

//These are the factors by which the font size adjusts the width and height of the overlay text
//I used a tool called text extractor to find these values
//These are approximate values and are not consistent with all cases. That is because the width factor differs from one letter to another.
const fontSizeLetterWidthFactor = 0.833
const fontSizeSpaceWidthFactor = 0.278
const fontSizeLetterHeightFactor = 1.282

function adjustFontSizeToFit(redaction: any) {
    if (redaction.FontSize && redaction.OverlayText) {
        //@ts-ignore
        let fontSize = parseInt(redaction.FontSize.substring(0, redaction.FontSize.length - 2));
        //We calculate the width and height that the overlay text have, then check if it fits in the redaction annotation, if not we decrease its size
        let textWidthPerFontUnit = findTextWidthPerFontUnit(redaction.OverlayText)
        redaction.Quads.map((quad: any) => {
            while (fontSize > 1) {
                const quadWidth = Math.abs(quad.x2 - quad.x1);
                const quadHeight = Math.abs(quad.y1 - quad.y3);
                if ((fontSize + 1) * textWidthPerFontUnit < quadWidth &&//we add 1 to the font size here because the overlay have a small margin in the left which could make the overlay text bigger than the redaction annotation
                    fontSize * fontSizeLetterHeightFactor < quadHeight) {
                    break;
                } else {
                    fontSize--;
                }
            }
        });
        const fontSizeString = fontSize + 'pt'
        //@ts-ignore
        redaction.FontSize = fontSizeString
    }
}

function findTextWidthPerFontUnit(overlayText: string) {
    let width = 0
    for (let i=0;i<overlayText.length;i++) {
        if (overlayText[i]===' ') {
            width+=fontSizeSpaceWidthFactor
        } else {
            width+=fontSizeLetterWidthFactor
        }
    }
    return width
}

function createSelectFontTypeAndSizeModal(fonts: string[], fontSizeOptions: string[], instance: any, afterSelectFontTypeAndSize: (fontType: string, fontSize: string) => void) {
    let selectFontTypeDiv = document.createElement('div');
    let selectFontSizeDiv = document.createElement('div');
    let selectFontTypeText = document.createElement('p');
    selectFontTypeText.innerText = 'Font Type: '
    selectFontTypeText.style.flexDirection='column'
    let selectFontType = document.createElement('select');
    fonts.map((font) => {
        let option = document.createElement('option');
        option.value = font;
        option.text = font;
        selectFontType.add(option);
    })

    selectFontTypeDiv.appendChild(selectFontTypeText)
    selectFontTypeDiv.appendChild(selectFontType)
    selectFontTypeDiv.style.display='flex'
    selectFontTypeDiv.style.justifyContent='space-between'
    selectFontTypeDiv.style.width='200px'
    selectFontType.value=fonts[0]
    let selectFontSizeText = document.createElement('p');
    selectFontSizeText.innerText = 'Font Size:'
    selectFontSizeText.style.flexDirection='column'
    let selectFontSize = document.createElement('select');
    fontSizeOptions.map((fontSize) => {
        let option = document.createElement('option');
        option.value = fontSize;
        option.text = fontSize;
        selectFontSize.add(option);
    })
    selectFontSize.value='9'
    selectFontSize.style.marginLeft='10px';
    selectFontSize.style.marginTop='10px';
    selectFontSizeDiv.appendChild(selectFontSizeText)
    selectFontSizeDiv.appendChild(selectFontSize)
    selectFontSizeDiv.style.display='flex'
    selectFontSizeDiv.style.width='200px'
    selectFontSizeDiv.style.alignItems='left'
    const modal: any = {
        dataElement: 'selectFontTypeAndSizeModal',
        body: {
            className: 'customMatchCategoryModal',
            style: {display: "flex", flexDirection: "column", alignItems: "center"}, // optional inline styles
            children: [selectFontTypeDiv, selectFontSizeDiv],
        },
        footer: {
            className: 'myCustomModal-footer footer',
            children: [
                {
                    title: 'Cancel',
                    button: true,
                    style: {},
                    className: 'modal-button cancel-form-field-button',
                    onClick: () => { instance.UI.closeElements(['selectFontTypeAndSizeModal']) }
                },
                {
                    title: 'Confirm',
                    button: true,
                    style: {},
                    className: 'modal-button confirm ok-btn',
                    onClick: () => {
                        instance.UI.closeElements(['selectFontTypeAndSizeModal']);
                        afterSelectFontTypeAndSize(selectFontType.value, selectFontSize.value)
                    }
                },
            ]
        }
    };
    return modal;
}

function createEditMarkModal(onCloseEditMarkModal: () => void, afterEditMark: (patientID: string, replacementText: string, startDate: string) => void) {
    let patientIDDiv = document.createElement('div');
    let patientIDText = document.createElement('p');
    patientIDText.innerText = 'Patient ID: '
    patientIDText.style.flexDirection='column'
    let patientIDInput = document.createElement('input')
    patientIDDiv.appendChild(patientIDText)
    patientIDDiv.appendChild(patientIDInput)
    patientIDDiv.style.display='flex'
    patientIDDiv.style.justifyContent='space-between'
    patientIDDiv.style.width='280px'
    let replacementTextDiv = document.createElement('div');
    let replacementTextText = document.createElement('p');
    replacementTextText.innerText = 'Replacement Text: '
    replacementTextText.style.flexDirection='column'
    let replacementTextInput = document.createElement('input')
    replacementTextInput.onchange = (event: any) => {
        replacementTextInput.focus()
    }
    replacementTextDiv.appendChild(replacementTextText)
    replacementTextDiv.appendChild(replacementTextInput)
    replacementTextDiv.style.display='flex'
    replacementTextDiv.style.justifyContent='space-between'
    replacementTextDiv.style.width='280px'
    let startDateDiv = document.createElement('div');
    let startDateText = document.createElement('p');
    startDateText.innerText = 'Start Date: '
    startDateText.style.flexDirection='column'
    startDateText.style.marginRight='65px'
    let startDateInput = document.createElement('input')
    startDateInput.type='date'
    startDateDiv.appendChild(startDateText)
    startDateDiv.appendChild(startDateInput)
    startDateDiv.style.display='flex'
    startDateDiv.style.width='280px'
    const modal: any = {
        dataElement: 'editMarkModal',
        disableBackdropClick: true,//so that we can enable the hotkeys onclose
        disableEscapeKeyDown: true,
        body: {
            className: 'customMatchCategoryModal',
            style: {display: "flex", flexDirection: "column", alignItems: "center"}, // optional inline styles
            children: [patientIDDiv, replacementTextDiv, startDateDiv],
        },
        footer: {
            className: 'myCustomModal-footer footer',
            children: [
                {
                    title: 'Cancel',
                    button: true,
                    style: {},
                    className: 'modal-button cancel-form-field-button',
                    onClick: onCloseEditMarkModal
                },
                {
                    title: 'Confirm',
                    button: true,
                    style: {},
                    className: 'modal-button confirm ok-btn',
                    onClick: () => {
                        afterEditMark(patientIDInput.value, replacementTextInput.value, startDateInput.value)
                    }
                },
            ]
        }
    };
    return modal;
}

function openMatchCategoryModal(categories: { label: string, type: string}[], instance: any, annotations: any[], afterPickingCategory: any, lastPattern: string|undefined, updateManualPattern: boolean, setLoading: (value: boolean) => void) {
    //After the 10.8 upgrade, this method had a problem where it would always open the first modal created. The fix for
    //now is to give the modal a new name every time we render it. This is creating a lot of unnecessary elements, so we
    //should find a better way at some point.
    const dataElementName = `MatchCategoryModal${annotations.map(a => {return a.Id}).join()}`
    let divInput1 = document.createElement('p');
    divInput1.innerText=`Select a category for this mark`
    divInput1.style.letterSpacing="0.15"
    divInput1.style.color="black"
    divInput1.style.font="17px Titillium Web, normal"
    divInput1.style.wordBreak="break-all"

    let divInput2 = document.createElement('select');

    if (categories.length>0) {
        //Note: the select menu can only listen to changes in the selection. This is a problem because if we have a default option and the user wants to select that option.
        //then it won't work because it is not a change in the selection. so I created this defaultOption which is similar to the actual default option, but it is a hidden
        //selection so that if the user selected the option in the menu, it will still be considered a change
        let defaultOption = document.createElement('option');
        defaultOption.value='';
        defaultOption.text = lastPattern ? getDisplayCategory(lastPattern) : categories[0].label;
        defaultOption.hidden=true
        divInput2.add(defaultOption);
    }
    divInput2.value = ''
    categories.map((category) => {
        let option = document.createElement('option');
        option.id=category.type+'id'
        option.value=category.type;
        option.text=category.label;
        divInput2.add(option);
    })
    divInput2.style.letterSpacing="0.1"
    divInput2.style.color="black"
    divInput2.style.font="15px Titillium Web, normal"
    divInput2.style.wordBreak="break-all"
    divInput2.style.height="44px"
    divInput2.style.width="250px"
    divInput2.onchange = (event: any) => {
        instance.UI.closeElements([dataElementName]);
        setLoading(true);
        setTimeout(() => {
            for (let annotation of annotations) {
                afterPickingCategory(annotation, event.target.value, updateManualPattern)
            }
            setLoading(false);
        }, 10)
    }

    divInput2.onblur = () => {
        divInput2.focus()//we always focus so that the user can press enter and it will directly select the option
    }
    divInput2.addEventListener('keypress', (event: any) => {
        if (event.key==='Enter') {
            instance.UI.closeElements([dataElementName]);
            if (categories.length>0) {
                let selectedOption = lastPattern ? lastPattern : categories[0].type;
                setLoading(true);
                setTimeout(() => {
                    for (let annotation of annotations) {
                        afterPickingCategory(annotation, selectedOption, updateManualPattern)
                    }
                    setLoading(false);
                }, 10)

            }
        }
    })

    const modal: any = {
        dataElement: dataElementName,
        body: {
            className: 'customMatchCategoryModal',
            style: {display: "flex", flexDirection: "column", alignItems: "center"}, // optional inline styles
            children: [divInput1, divInput2],
        },
    };
    instance.UI.addCustomModal(modal);
    setTimeout(() => {
        //sometimes when you open the modal immediately, you get an empty modal. I guess it might be because it takes a small time for the modal to be added. that is
        //why I added this timeout
        instance.UI.openElements([dataElementName]);
        divInput2.focus()//we always focus so that the user can press enter and it will directly select the option
    }, 100)
}

export function sortByOrderOfAppearnceInDocument(annotation1: any, annotation2: any) {
    //sorting by location in document then location in page
    if (annotation1.getPageNumber() !== annotation2.getPageNumber()) {
        return annotation1.getPageNumber() - annotation2.getPageNumber()
    } else if (annotation1.getY() !== annotation2.getY()) {
        return annotation1.getY() - annotation2.getY()
    } else {
        return annotation1.getX() - annotation2.getX()
    }
}

export const getDisplayCategory = (category: string) => {
    return category.indexOf(CATEGORY_SPLITTER) !== -1 ? category.split(CATEGORY_SPLITTER)[1] : category;
}
