import {DATE_OFFSET, DeidData, RELATIVE_DATE} from "../models/DeidData";
import {utils, writeFile} from "xlsx";

export const USE_RELATIVE_DAY = 'Use Relative Day';
export const RELATIVE_DAY_PREFIX = 'Relative Day Prefix';
export const USE_RANDOMIZED_DATE_OFFSET = 'Use Randomized Date Offset';
export const PATIENT_ID_COLUMN_NAME = 'Patient ID Column Name';
export const CHANGE_INTERVALS_TO_MIDPOINTS = 'Change Intervals to Midpoints';
export const DATE_TRANSFORMATION = "Date Transformation";

export async function lookupFileGenerator(deidFileName: string, deidData: DeidData, categoryToColumnMap: Map<string, string | undefined>, midpointCategories: Set<string>) {
    const projectWorkbook = utils.book_new();

    let dataSheet = await deidData.buildLookupFileDataSheet(categoryToColumnMap, new Map(), new Set())
    utils.book_append_sheet(projectWorkbook, dataSheet, "Data")
    // @ts-ignore
    for (const intervalColumn of deidData.intervalColumns) {
        const hierarchySheet = await buildHierarchySheet(deidData, intervalColumn);
        if (hierarchySheet) {
            utils.book_append_sheet(projectWorkbook, hierarchySheet, intervalColumn + "-hierarchy");
        }
    }
    utils.book_append_sheet(projectWorkbook, buildParameterSheet(deidData, midpointCategories, categoryToColumnMap), "Parameters")

    const lookupsName = deidFileName.split(".")[0] + '_lookups.xlsx'
    writeFile(projectWorkbook, lookupsName)

}

async function buildHierarchySheet(deidData: DeidData, columnName: string) {
    const hierarchy = await deidData.getHierarchyParsed(columnName);
    if (hierarchy) {
        return utils.aoa_to_sheet(hierarchy);
    } else {
        return undefined;
    }
}

function buildParameterSheet(deidData: DeidData, midpointCategories: Set<string>, categoryToColumnMap: Map<string, string | undefined>) {
    let rows: string[][] = []
    rows.push(['Type', 'Parameter', 'Value']);
    rows.push(['Date', USE_RELATIVE_DAY, deidData.dateMode === RELATIVE_DATE ? 'Y' : 'N']);
    rows.push(['Date', RELATIVE_DAY_PREFIX, 'Day']); //I think we can hard code this
    rows.push(['Date', USE_RANDOMIZED_DATE_OFFSET, deidData.dateMode === DATE_OFFSET ? 'Y' : 'N']);
    //rows.push(['Patient ID', 'One Patient Per Row', 'Y']);
    rows.push(['Patient ID', PATIENT_ID_COLUMN_NAME, deidData.patientIDColumn ?? ""]);
    midpointCategories.forEach(category => {
        if (categoryToColumnMap.has(category)) {
            rows.push([CHANGE_INTERVALS_TO_MIDPOINTS, categoryToColumnMap.get(category)!, 'Y']);
        }
    })
    return utils.aoa_to_sheet(rows);
}