import SearchStatus from "./SearchPanel/SearchStatus";
import {redactionTypeMap} from "../../constants";

const onResultThrottleTimeout = 100;
let isStillProcessingResults = false;

export const executeRedactionSearch = (searchTerms: any[], settings: any, documentViewer: any, searchModes: any, filterFunction: (searchResult: any) => Promise<boolean>, setSearchStatus: (s: string) => void, resultsToKeep: any[]) => {

    const options = {
        regex: true,
        startPage: settings.startPage,
        endPage: settings.endPage,
        wholeWord: settings.wholeWord
    };

    // @ts-ignore
    const { textSearch, patternSearch } = searchTerms;
    let searchArray = [...textSearch];
    patternSearch.forEach((ps: any) => {
        searchArray.push(ps.regex.source)
    })

    const searchString = searchArray.join('|');

    // If search string is empty we return and clear searches, or we send the search logic
    // into an infinite loop
    if (searchString === '') {
        documentViewer.clearSearchResults();
        documentViewer.displayAdditionalSearchResults(resultsToKeep)
        return;
    }

    setSearchStatus(SearchStatus.SEARCH_IN_PROGRESS);
    documentViewer.trigger('processStarted', 'search')
    searchTextFull(searchString, options, documentViewer, searchModes, filterFunction, setSearchStatus, patternSearch, resultsToKeep)
}

export const loadNLPRedactionSearch = (documentViewer: any, json: any, searchModes: any, setSearchStatus: (s: string) => void, filterFunction: (searchResult: any) => Promise<boolean>) => {
    setSearchStatus(SearchStatus.NLP_LOAD_IN_PROGRESS);
    documentViewer.trigger('processStarted', 'search')
    loadNLPResults(json, documentViewer, searchModes, setSearchStatus, filterFunction)
}

function buildSearchModeFlag(options: any = {}, SearchModes: any) {
    let searchMode = SearchModes.PAGE_STOP | SearchModes.HIGHLIGHT;

    if (options.caseSensitive) {
        searchMode |= SearchModes.CASE_SENSITIVE;
    }
    if (options.wholeWord) {
        searchMode |= SearchModes.WHOLE_WORD;
    }
    if (options.wildcard) {
        searchMode |= SearchModes.WILD_CARD;
    }
    if (options.regex) {
        searchMode |= SearchModes.REGEX;
    }

    searchMode |= SearchModes.AMBIENT_STRING;

    return searchMode;
}

export const searchTextFull = (searchValue: string, options: any, documentViewer: any, searchModes: any,
                               filterFunction: (searchResult: any) => Promise<boolean>, setSearchStatus: (s: string) => void,
                               patternsInUse: { label: string, type: string, regex: RegExp }[], resultsToKeep: any[]) => {
    //This is what is going on in the webviewer code
    //dispatch(actions.searchTextFull(searchValue, options));

    const searchMode = buildSearchModeFlag(options, searchModes);
    let doneCallback = () => { };

    let hasActiveResultBeenSet = false;
    let throttleResults: any[] = [];
    let resultTimeout: NodeJS.Timeout | null;

    async function onResult(result: any) {
        const shouldFilter = filterFunction && await filterFunction(result)
        if (shouldFilter) {
            console.log('Removing search result')
            return
        } else {
            mapResultToType(result, patternsInUse)
            throttleResults.push(result);
        }

        if (!resultTimeout) {
            if (!isStillProcessingResults) {
                isStillProcessingResults = true;
            }

            resultTimeout = setTimeout(() => {
                documentViewer.displayAdditionalSearchResults(throttleResults);
                throttleResults = [];
                resultTimeout = null;
                doneCallback();
            }, onResultThrottleTimeout);
        }

        if (!hasActiveResultBeenSet) {
            // when full search is done, we make first found result to be the active result
            documentViewer.setActiveSearchResult(result);
            hasActiveResultBeenSet = true;
        }
    }

    function searchInProgressCallback(isSearching: boolean) {
        // execute search listeners when search is complete, thus hooking functionality search in progress event.
        if (isSearching === false) {
            doneCallback = () => {
                const results = documentViewer.getPageSearchResults();
                const searchOptions = {
                    // default values
                    caseSensitive: false,
                    wholeWord: false,
                    wildcard: false,
                    regex: false,
                    searchUp: false,
                    ambientString: true,
                    // override values with those user gave
                    ...options,
                };
                // const nextResultIndex = store?.getState().search?.nextResultIndex;
                //
                // const result = results[nextResultIndex];
                // if (result) {
                //     core.setActiveSearchResult(result);
                // }
                // const searchListeners = getSearchListeners() || [];
                // searchListeners.forEach((listener) => {
                //     try {
                //         listener(searchValue, searchOptions, results);
                //     } catch (e) {
                //         console.error(e);
                //     }
                // });
                documentViewer.trigger('processEnded', 'search')
            };
            isStillProcessingResults = false;

            if (!resultTimeout) {
                doneCallback();
            }
            documentViewer.removeEventListener('searchInProgress', searchInProgressCallback);
        }
    }

    function onDocumentEnd() {
        documentViewer.displayAdditionalSearchResults(resultsToKeep)
        setSearchStatus(SearchStatus.SEARCH_DONE);
    }

    const numPagesToSearch: number = (options.startPage && options.endPage) ? options.endPage - options.startPage + 1 : documentViewer.getPageCount();
    let pagesSearched: number = 0;

    function onPageEnd() {
        pagesSearched++;
        window.dispatchEvent(new CustomEvent('pageSearched', {detail: {pagesSearched, numPagesToSearch}}))
    }

    function handleSearchError(error: any) {
        //dispatch(actions.setProcessingSearchResults(false));
        console.error(error);
    }
    const textSearchInitOptions: any = {
        'fullSearch': true,
        onResult,
        onDocumentEnd,
        onPageEnd,
        'onError': handleSearchError,
    };

    if (options.startPage) {
        textSearchInitOptions.startPage = options.startPage;
    }
    if (options.endPage) {
        textSearchInitOptions.endPage = options.endPage;
    }

    documentViewer.clearSearchResults();
    documentViewer.textSearchInit(searchValue, searchMode, textSearchInitOptions);
    documentViewer.addEventListener('searchInProgress', searchInProgressCallback);
}

export const loadNLPResults = async (json: any, documentViewer: any, searchModes: any, setSearchStatus: (s: string) => void, filterFunction: (searchResult: any) => Promise<boolean>) => {
    let nlpResults = json.annotations
    let nlpPagesText = json.pages
    let throttleResults: any[] = [];
    const doc = await documentViewer.getDocument()
    let resultsLoaded = 0
    const numOfResults = nlpResults.length
    const resultsByPage: Map<number, any[]> = nlpResults.reduce((map: Map<number, any[]>, annotation: any) => {
        const pageNumber = annotation["page_no"];
        return map.set(pageNumber, (map.get(pageNumber) || []).concat(annotation));
    }, new Map<number, any[]>());

    for (const pageNum of Array.from(resultsByPage.keys())) {
        let pageResults = resultsByPage.get(pageNum)
        const pageText = (await doc.loadPageText(pageNum)).replaceAll('\n', ' ');
        assignOccurrenceIndexToResults(pageResults!, nlpPagesText[pageNum-1])
        for (const result of pageResults!) {
            const startIndex = getStartIndex(pageText, result["original"], result.PageOccurenceIndex)
            let quads: any[] = []
            if (startIndex===pageText.length) {//This means there was an error and we weren't able to find the search result
                let notFound=false
                const splittedText = result["original"].split(" ")//We will try to find the results by splitting the words of the search result and finding each word individually
                for (const text of splittedText) {
                    const occurenceIndex = countOccurrences(nlpPagesText[pageNum-1].substring(0, result["start_char"]), text)+1
                    const textStartIndex = getStartIndex(pageText, text, occurenceIndex)
                    if (textStartIndex===pageText.length) {
                        notFound=true
                        break
                    } else {
                        const textQuads = await doc.getTextPosition(result["page_no"], textStartIndex, textStartIndex + text.length)
                        quads=quads.concat(combineQuads(textQuads))
                    }
                }
                if (notFound) {//if we still can't find the result then we just skip
                    continue
                }
            }
            if (quads.length===0) {
                quads = await doc.getTextPosition(result["page_no"], startIndex, startIndex + result["original"].length);
                quads = combineQuads(quads);
            }
            const searchResult = createSearchResultFromNLPResult(result, resultsLoaded, quads)
            searchResult.type = result["match_category"]
            const shouldFilter = filterFunction && await filterFunction(searchResult)
            if (shouldFilter) {
                console.log('Removing search result')
                continue
            } else {
                throttleResults.push(searchResult);
                resultsLoaded++
            }
        }
        documentViewer.displayAdditionalSearchResults(throttleResults)
        window.dispatchEvent(new CustomEvent('nlpResultLoaded', {detail: {resultsLoaded, numOfResults}}))
        throttleResults=[]
    }
    setSearchStatus(SearchStatus.SEARCH_DONE);
    documentViewer.trigger('processEnded', 'loadNlp')
}

function assignOccurrenceIndexToResults(results: any[], pageText: string) {
    results.forEach((result) => {
        result.PageOccurenceIndex = countOccurrences(pageText.substring(0, result["start_char"]-1), result["original"])+1
    })
}

function countOccurrences(string: string, subString: string): number {
    // Escape special characters in the subString to avoid regex interpretation
    const escapedSubString = subString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    // Create a regular expression with the escaped subString and the global flag
    const regex = new RegExp(escapedSubString, 'g');
    // Use match() to find all occurrences of the subString in the string
    const matches = string.match(regex);
    // Return the number of matches found
    return matches ? matches.length : 0;
}


function getStartIndex(string: string, subString: string, index: number) {
    return string.split(subString, index).join(subString).length;
}

function combineQuads(quads: any[]) {
    const extension = 1
    let combinedQuads: any[]=[]
    let x1=quads[0].x1, x2=quads[0].x2, y1=quads[0].y1, y3 = quads[0].y3
    quads.forEach((quad) => {
        if (x1<=quad.x1) {
            x2 = Math.max(x2, quad.x2)
            y1 = Math.max(y1, quad.y1)
            y3 = Math.min(y3, quad.y3)
        } else {//there could be multiple combined quads if the result is in multiple lines
            combinedQuads.push(createQuad(x1, x2, y1, y3, extension))
            x1=quad.x1
            x2=quad.x2
            y1=quad.y1
            y3 = quad.y3
        }
    })
    combinedQuads.push(createQuad(x1, x2, y1, y3, extension))
    return combinedQuads
}

function createQuad(x1: number, x2: number, y1: number, y3: number, extension: number) {
    x1 = x1 - extension
    x2 = x2 + extension
    y1 = y1 + extension
    y3 = y3 - extension
    return {
        x1: x1 ,
        x2: x2,
        y1: y1,
        y2: y1,
        x3: x2,
        y3: y3,
        x4: x1,
        y4: y3,
        getPoints: () => {
            return {
                x1: x1,
                x2: x2,
                y1: y1,
                y2: y1,
                x3: x2,
                y3: y3,
                x4: x1,
                y4: y3
            }
        }
    }
}

function createSearchResultFromNLPResult(nlpResult: any, index: number, quads: any[], pl=0) {
    return {
        ambientStr: nlpResult["original"],
        ambient_str: nlpResult["original"],
        index: index,
        pageNum: nlpResult["page_no"]+pl,
        page_num: nlpResult["page_no"]+pl,
        quads: quads,
        resultCode: 2,
        resultStr: nlpResult["original"],
        resultStrEnd: nlpResult["original"].length,
        resultStrStart: 0,
        result_str: nlpResult["original"],
        result_str_end: nlpResult["original"].length,
        result_str_start: 0,
        shouldHide: false,
        type: nlpResult["original"],
        AIGenerated: true
    }
}

const mapResultToType = (result: any, patternsInUse: { label: string, type: string, regex: RegExp }[]) => {
    if (!patternsInUse) {
        result.type = redactionTypeMap['TEXT'];
        return result;
    }

    if (patternsInUse.length === 1) {
        result.type = patternsInUse[0].type
    } else {
        // Iterate through the patterns and return the first match
        let resultType = undefined
        for (let pattern of patternsInUse) {
            if (pattern.type === 'text') {
                continue;
            }
            if (patternMatchesResult(result, pattern.regex)) {
                resultType = pattern.type
                break;
            }
        }

        // If it didn't match any of the patterns, return the default type which is text
        result.type = resultType === undefined ? redactionTypeMap['TEXT'] : resultType;
    }
    // And also set the icon to display in the panel. If no icon provided use the text icon
    // const { icon = 'icon-form-field-text' } = searchPatterns[result.type] || {};
    // result.icon = icon;
    return result;
}

//This runs the pattern against the ambient string of the search result (containing the match with surrounding words).
//That's so that the pattern can properly use lookbehinds or lookaheads. However, it does require the actual match to
//be in the resultStr, to make sure we're not matching something before or after the result.
function patternMatchesResult(searchResult: any, pattern: RegExp) {
    //First check if the pattern matches the result string, if so we don't need to test further.
    if (pattern.test(searchResult.resultStr)) {
        return true;
    }

    //TODO the ambient string may not be long enough. We can expand it using the same logic as in smart-filter-manager.
    const result = pattern.exec(searchResult.ambientStr);
    if (result !== null && result.index >= searchResult.resultStrStart && result.index <= searchResult.resultStrEnd) {
        return true
    }
    return false
}